<template>
  <b-modal
    id="root-upload-modal"
    hide-footer
    title="Upload your files"
    size="lg"
    @show="show"
  >
    <span class="rc-disclaimer">
      Please note that this is a working directory, files are kept for
      <span v-if="s3Config">{{ s3Config.retention_days }}</span> days.
    </span>

    <div class="rc-upload text-center my-3">
      <template v-if="files.length">
        <div class="list-group rc-uploads">
          <transition-group name="list" tag="div">
            <div
              v-for="(file, index) in files"
              :key="file.name"
              class="list-group-item"
            >
              <div class="w-100">
                <div class="d-flex justify-content-between">
                  <div>
                    <RcIcon name="file" scale="1.3" class="mr-1"></RcIcon>
                    <span class="text-left text-break">{{ file.name }}</span>
                  </div>
                  <button class="btn" @click="uploadCancel(file)">
                    <RcIcon name="closeCircle" scale="1.1"></RcIcon>
                  </button>
                </div>
                <div
                  class="d-flex justify-content-between mt-4 mb-2 text-muted"
                >
                  <small class="text-left"
                    >{{
                      usePrettyBytes(
                        uploads[file.name].stats.totalUploaded || 0
                      )
                    }}/{{ usePrettyBytes(file.size || 0) }}
                    @
                    {{
                      usePrettyBytes(uploads[file.name].stats.speed || 0)
                    }}/sec</small
                  >
                  <small class="text-right">{{
                    prettyTime(uploads[file.name].stats.secondsLeft)
                  }}</small>
                </div>

                <div class="d-inline-flex w-100">
                  <rc-btn
                    class="my-rounded-left"
                    :disabled="uploads[file.name]['disabled']"
                    :variant="uploads[file.name]['variant']"
                    @click="uploadToggleResume(file)"
                    ><RcIcon :name="getIcon(file)"></RcIcon
                  ></rc-btn>

                  <!--Value and label-->
                  <div
                    class="progress flex-grow-1 my-rounded-right"
                    style="height: 36px"
                    :label="file.name"
                  >
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{
                        width: uploads[file.name]['percent'] + '%'
                      }"
                      :aria-valuenow="uploads[file.name]['percent'].toFixed(2)"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :class="[
                        {
                          'progress-bar-striped progress-bar-animated':
                            uploadActive(file)
                        },
                        'bg-' + uploads[file.name]['variant']
                      ]"
                    >
                      <div>
                        <strong
                          >{{
                            uploads[file.name]["percent"].toFixed(2)
                          }}%</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </template>
      <template v-else>
        <div class="rc-upload-drag-zone">
          <RcIcon name="download" scale="3"></RcIcon>
          <b-form-file
            v-model="files"
            placeholder="Choose a file or drag it here"
            drop-placeholder="Drop file here..."
            multiple
            :disabled="!!files.length"
          >
            <template #file-name></template>
          </b-form-file>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { usePrettyBytes } from "@/composables/formats";
import RcIcon from "@/libvuePure/components/icons/Base.vue";

import {
  UPLOADS_GET_S3_SIGNATURE,
  UPLOADS_GET_ASSETS,
  UPLOADS_GET_S3_CONFIG,
  UPLOADS_SET_PROGRESS
} from "../actions";

import store from "#/stores";
import sha256 from "js-sha256";
import Vue from "vue";
import Config from "#/config";

export default {
  name: "RcUpload",
  components: {
    RcIcon
  },
  setup() {
    return { usePrettyBytes };
  },
  data() {
    return {
      uploader: null,
      evaporate: null,
      files: [],
      uploads: {},
      compWidth: null
    };
  },

  computed: {
    ...mapState({
      s3Config: (state) => state.upload.s3Config.data,
      user: (state) => state.auth.user,
      uploadInProgress: (state) => state.upload.progress.active
    }),
    prettyTime: () => (num) => {
      var suffix = "left";

      var seconds = parseInt(num, 10);
      var days = Math.floor(seconds / (3600 * 24));
      seconds -= days * 3600 * 24;
      var hrs = Math.floor(seconds / 3600);
      seconds -= hrs * 3600;
      var mnts = Math.floor(seconds / 60);
      seconds -= mnts * 60;

      if (days) {
        return `${days} days ${hrs} hours and ${mnts} minutes ${suffix}`;
      } else if (hrs) {
        return `${hrs} hours and ${mnts} minutes ${suffix}`;
      } else if (mnts) {
        return `${mnts} minutes and ${seconds} seconds ${suffix}`;
      } else if (seconds) {
        return `${seconds} seconds ${suffix}`;
      }

      return "upload completed";
    },
    cssProgress() {
      let classes = "";

      if (this.uploadActive(file)) {
        classes = "progress-bar-striped progress-bar-animated ";
      }

      return classes;
    }
  },
  watch: {
    files: function (files, oldFiles) {
      if (files.length > oldFiles.length) {
        this.initUploadState(files);
      }
    }
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    ...mapActions({
      getSignature: UPLOADS_GET_S3_SIGNATURE
    }),
    beforeWindowUnload(e) {
      e.preventDefault();
      if (this.uploadInProgress) {
        e.returnValue = "";
      }
    },
    show() {
      this.s3Config
        ? this.createUploader()
        : store.dispatch(UPLOADS_GET_S3_CONFIG).then(() => {
            this.createUploader();
          });
    },
    createUploader() {
      this.uploader = this.$evaporate.create({
        aws_key: this.s3Config.aws_key,
        bucket: this.s3Config.aws_bucket,
        awsRegion: this.s3Config.aws_region,
        cloudfront: true,
        computeContentMd5: true,
        cryptoMd5Method: (d) => btoa(this.$sparkMD5.ArrayBuffer.hash(d, true)),
        cryptoHexEncodedHash256: sha256,
        s3FileCacheHoursAgo: 24,
        allowS3ExistenceOptimization: true,
        logging: !Config.isProduction(),
        customAuthMethod: this.customAuth
      });
      this.uploader.then((evaporate) => {
        if (!this.evaporate) {
          this.evaporate = evaporate;
        }
      });
    },
    customAuth(
      signParams,
      signHeaders,
      stringToSign,
      signatureDateTime,
      canonicalRequest
    ) {
      return this.getSignature({
        signParams,
        signHeaders,
        stringToSign,
        signatureDateTime,
        canonicalRequest
      });
    },
    getIcon(file) {
      return this.uploads[file.name]["icon"];
    },
    initUploadState(files) {
      files.forEach((file) => {
        Vue.set(this.uploads, file.name, {});
        Vue.set(this.uploads[file.name], "percent", 0);
        Vue.set(this.uploads[file.name], "error", "");
        Vue.set(this.uploads[file.name], "stats", {});
        Vue.set(this.uploads[file.name], "variant", "secondary");
        Vue.set(this.uploads[file.name], "paused", "pause");
        Vue.set(this.uploads[file.name], "disabled", false);
        Vue.set(this.uploads[file.name], "icon", "pauseCircle");
        this.uploadFile(file);
      });
    },
    uploadFile(file) {
      this.evaporate
        .add({
          file: file,
          name: `${this.s3Config.path}/${file.name}`,
          progress: (percent, stats) =>
            this.uploadProgress(percent, stats, file),
          complete: (xhr, awsObjectKey) =>
            this.uploadCompleted(xhr, awsObjectKey, file),
          error: (mssg) => this.uploadError(mssg, file),
          paused: () => this.uploadPaused(file),
          pausing: () => this.uploadPausing(file),
          resumed: () => this.uploadResumed(file),
          cancelled: () => this.uploadCanceled(file),
          started: () => this.uploadStarted(file),
          uploadInitiated: (s3Id) => console.log("Upload Initiated", s3Id),
          warn: (mssg) => console.log("Warning", mssg)
        })
        .then(
          (awsObjectKey) => this.uploadSuccess(file),
          (reason) => console.log("File did not upload sucessfully:", reason)
        );
    },
    uploadActive(file) {
      return this.uploads[file.name]["percent"] !== 100;
    },
    uploadStarted(file) {
      this.$store.commit(UPLOADS_SET_PROGRESS, {
        fields: {
          active: true,
          seen: false,
          variant: "progress"
        }
      });
      this.uploads[file.name]["variant"] = "secondary";
      this.uploads[file.name]["paused"] = "pause";
      this.uploads[file.name]["disabled"] = false;
      this.uploads[file.name]["icon"] = "pauseCircle";
    },
    uploadProgress(percent, stats, file) {
      this.uploads[file.name]["stats"] = stats;
      this.uploads[file.name]["percent"] = percent * 100;

      if (this.uploads[file.name]["percent"] > 33) {
        this.uploads[file.name]["variant"] = "progress";
      }
      if (this.uploads[file.name]["percent"] > 66) {
        this.uploads[file.name]["variant"] = "success";
      }
    },
    uploadToggleResume(file) {
      if (this.uploads[file.name]["paused"] === "pause") {
        this.evaporate.pause(
          `${this.s3Config.aws_bucket}/${this.s3Config.path}/${file.name}`
        );
      } else if (this.uploads[file.name]["paused"] === "resume") {
        this.evaporate.resume(
          `${this.s3Config.aws_bucket}/${this.s3Config.path}/${file.name}`
        );
      }
    },
    uploadPaused(file) {
      this.$store.commit(UPLOADS_SET_PROGRESS, {
        fields: {
          active: true,
          seen: false,
          variant: "warning"
        }
      });
      this.uploads[file.name]["variant"] = "warning";
      this.uploads[file.name]["disabled"] = false;
      this.uploads[file.name]["paused"] = "resume";
      this.uploads[file.name]["icon"] = "playCircle";
    },
    uploadPausing(file) {
      this.uploads[file.name]["disabled"] = true;
      this.uploads[file.name]["paused"] = "pausing";
      this.uploads[file.name]["icon"] = "warning";
    },
    uploadResumed(file) {
      this.uploads[file.name]["disabled"] = false;
      this.uploads[file.name]["paused"] = "pause";
      this.uploads[file.name]["icon"] = "pauseCircle";
    },
    uploadCancel(file) {
      this.evaporate.cancel(
        `${this.s3Config.aws_bucket}/${this.s3Config.path}/${file.name}`
      );
    },
    uploadCanceled(file) {
      this.$store.commit(UPLOADS_SET_PROGRESS, {
        reset: true
      });
      this.files = this.files.filter((f) => f.name !== file.name);
    },
    uploadSuccess(file) {
      this.$store.commit(UPLOADS_SET_PROGRESS, {
        fields: {
          active: false,
          seen: false,
          variant: "success"
        }
      });
      this.uploads[file.name]["disabled"] = false;
      this.uploads[file.name]["variant"] = "success";
      this.uploads[file.name]["icon"] = "success";
      this.toast("success", file.name);
    },
    uploadCompleted(xhr, awsObjectKey, file) {
      this.$store.commit(UPLOADS_SET_PROGRESS, {
        fields: {
          active: false,
          seen: false,
          variant: "success"
        }
      });
      this.$store.dispatch(UPLOADS_GET_ASSETS);
      this.files = this.files.filter((f) => f.name !== file.name);
    },
    uploadError(mssg, file) {
      this.$store.commit(UPLOADS_SET_PROGRESS, {
        fields: {
          active: true,
          seen: false,
          variant: "danger"
        }
      });
      this.uploads[file.name]["error"] = mssg;
      this.toast("danger", mssg);
    },
    toast(variant, value) {
      this.$bvToast.toast(value, {
        title: `${
          variant == "success"
            ? "Upload successfull"
            : "An error occured during upload"
        }`,
        variant,
        to: {
          name: "upload.list"
        }
      });
    }
  }
};
</script>

<style lang="scss">
.rc-upload-drag-zone {
  height: 10rem;
  background-color: #f9f9f9;
  border: 0.1875rem dashed rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;

  .rc-download {
    position: absolute;
    top: 50px;
    left: calc(50% - 15px);
  }

  .custom-file {
    height: 100%;

    label,
    input {
      height: 100%;
    }

    label {
      padding: 95px 0 0 0;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      border: none;

      &::after {
        opacity: 0;
      }

      &.dragging {
        color: #888;
        background-color: #f2f2f2;
      }
    }
  }

  .custom-file-input:disabled ~ .custom-file-label {
    background-color: transparent;
  }
}

.rc-uploads {
  min-height: 10rem;

  .list-group-item {
    padding: 1.25rem;
    background-color: #f9f9f9;
  }
  .progress {
    background-color: #eee;
  }
  .progress-bar {
    transition: all 1s ease;
  }
  .my-rounded-right {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }
  .my-rounded-left {
    z-index: 5;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .btn {
    padding: 0.1rem 0.5rem;
    &::before {
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
  .progress-bar.bg-progress {
    background-color: var(--blue);
  }
  .btn-progress {
    color: white;
    background-color: var(--blue);
  }
}
</style>
