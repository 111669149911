<template>
  <div>
    <!-- TODO: Add the ability for the label cols width parameters to be passed from page -->
  
    <div>
      <div
        v-for="field in form.fields"
        ref="formgroup"
        :key="field.name"
        :validated="false"
        :aria-describedby="`${field.name}-feedback`"
        class="row no-gutters mb-2"
      >
        <label
          :for="field.name"
          class="col-form-label col-12"
          :class="'col-lg' + labelColsLg"
          >{{
            !field.required
              ? `${field.label} ${$t("common.optional")}`
              : field.label
          }}</label
        >
        <div class="col">
          <input
            :id="field.name"
            :ref="field.name"
            v-model="field.value"
            :name="field.name"
            :type="field.type"
            class="form-control"
            :disabled="field.disabled"
            autocorrect="off"
            spellcheck="false"
            :class="field.validate.isValid != false ? '' : 'is-invalid'"
            :aria-invalid="field.validate.isValid != false ? null : true"
            :aria-describedby="
              field.validate.isValid != false ? null : `${field.name}-feedback`
            "
            @blur="validate(field)"
          />

          <div
            v-if="field.validate.isValid == false"
            :id="`${field.name}-feedback`"
            aria-live="assertive"
            aria-atomic="true"
            class="invalid-feedback d-block"
          >
            {{ field.validate.message }}
          </div>
          <div v-if="field.validate.helpText" class="help-block">
            {{ field.validate.helpText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formValidationMixin } from "@/libvue/components/form/mixins";

export default {
  mixins: [formValidationMixin],
  props: {
    form: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "md"
    },
    labelColsLg: {
      type: Number,
      default: 12
    }
  },
  created() {
    setTimeout(() => {
      this.$nextTick(() => this.$refs.formgroup[0].$children[0].$el.focus());
    }, 350);
  }
};
</script>
