function appLoad(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/libvue/apps/auth/${view}.vue`
    );
}

function siteLoad(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `#/pages/${view}.vue`);
}

const routes = [
  {
    path: "login",
    component: siteLoad("auth/Login"),
    name: "auth.login",
    meta: {
      title: "pageMeta.login.title",
      layout: "util"
    }
  },
  {
    path: "login/pin",
    component: appLoad("LoginPin"),
    name: "auth.login.pin",
    meta: { layout: "util", title: "pageMeta.pin.title" }
  }
];

export default routes;
