<template>
  <PrismEditor
    v-model="code"
    class="xml-editor"
    :highlight="highlighter"
  ></PrismEditor>
</template>

<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-solarizedlight.css"; // import syntax highlighting styles

export default {
  components: {
    PrismEditor
  },
  props: {
    xmlString: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      code: ""
    };
  },
  watch: {
    code(code) {
      this.$emit("textChanged", code);
    }
  },
  created: function () {
    this.code = this.xmlString;
  },

  methods: {
    highlighter(xmlString) {
      return highlight(xmlString, languages.js); // languages.<insert language> to return html with markup
    }
  }
};
</script>

<style lang="scss">
/* required class */
.prism-editor-wrapper .prism-editor__editor,
.prism-editor-wrapper .prism-editor__textarea {
  overflow: hidden !important;
}
.xml-editor {
  font-family: "Radio-Canada", sans-serif;
  line-height: 1.3em;

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
}
</style>
