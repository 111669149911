import axios from "@/utils/axios.js";

import * as constants from "./actions";
import { LIB_DRAWER_SET_STATE } from "@/libvue/components/actions";

function getDefaultState() {
  return {
    user: {
      storage: {
        data: {},
        status: "",
        errors: {},
        assets: {
          data: [],
          status: "",
          errors: ""
        }
      },
      storages: {
        data: [],
        status: "",
        errors: ""
      }
    },
    team: {
      storage: {
        data: {},
        status: "",
        errors: "",
        assets: {
          data: [],
          status: "",
          errors: ""
        },
        asset: {
          data: {},
          status: "",
          errors: "",
          emptyFeedback: "There is no asset corresponding to this query",
          tabIndex: "mediainfo",
          tabTitle: "Asset Details",
          download: {
            data: {},
            status: "",
            errors: ""
          },
          playlist: {
            data: {},
            status: "",
            errors: ""
          }
        }
      },
      localStorage: {
        data: {},
        status: "",
        errors: "",
        assets: {
          data: [],
          status: "",
          errors: ""
        },
        asset: {
          data: {},
          status: "",
          errors: "",
          emptyFeedback: "There is no asset corresponding to this query",
          tabIndex: "file",
          tabTitle: "Asset Details",
          playlist: {
            data: {},
            status: "",
            errors: ""
          }
        }
      },
      storages: {
        data: [],
        status: "",
        errors: ""
      }
    }
  };
}

const actions = {
  [constants.TEAM_STORAGES_REQUEST]: ({ commit, rootState }, storage_uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_STORAGES_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      axios
        .get(`teams/${team_uid}/storages/${storage_uid}`)
        .then((response) => {
          commit(constants.TEAM_STORAGES_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_STORAGES_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_STORAGES_GET_ASSET]: ({ commit, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_STORAGES_GET_ASSET);

      const team_uid = rootState.auth.user.team_uid;
      const storage_uid = payload.source_storage_id;
      const file_rel_path = payload.file_rel_path;

      axios
        .get(
          `teams/${team_uid}/storages/${storage_uid}/get-details/${file_rel_path}`
        )
        .then((response) => {
          commit(constants.TEAM_STORAGES_GET_ASSET_SUCCESS, {
            ...response,
            storage_uid
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_STORAGES_GET_ASSET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_STORAGES_GET_LOCAL_ASSET]: (
    { commit, rootState },
    params
  ) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_STORAGES_GET_LOCAL_ASSET);

      const team_uid = rootState.auth.user.team_uid;
      const storage_uid = params?.storage_id;
      const file_rel_path = params?.file_rel_path;

      axios
        .get(
          `teams/${team_uid}/storages/${storage_uid}/get-details/${file_rel_path}`
        )
        .then((response) => {
          commit(constants.TEAM_STORAGES_GET_LOCAL_ASSET_SUCCESS, {
            ...response,
            storage_uid
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_STORAGES_GET_LOCAL_ASSET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_STORAGES_PATCH_ASSET]: ({ commit, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_STORAGES_PATCH_ASSET);

      const team_uid = rootState.auth.user.team_uid;
      const storage_uid = payload.source_storage_id;
      const file_rel_path = payload?.file_rel_path;
      const flowlog_uid = payload.flowlog_uid;

      delete payload.flowlog_uid;
      axios
        .patch(
          `teams/${team_uid}/storages/${storage_uid}/get-details/${file_rel_path}`,
          payload
        )
        .then((response) => {
          commit(constants.TEAM_STORAGES_PATCH_ASSET_SUCCESS, {
            payload: { flowlog_uid },
            rootState
          });
          commit(LIB_DRAWER_SET_STATE, {
            visible: false
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_STORAGES_PATCH_ASSET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_STORAGES_DELETE_ASSET]: ({ commit, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_STORAGES_DELETE_ASSET);

      const team_uid = rootState.auth.user.team_uid;
      const storage_uid = payload.source_storage_id;
      const file_rel_path = payload?.file_rel_path;
      axios
        .delete(
          `teams/${team_uid}/storages/${storage_uid}/get-details/${file_rel_path}`
        )
        .then((response) => {
          commit(constants.TEAM_STORAGES_DELETE_ASSET_SUCCESS, {
            rootState,
            payload
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_STORAGES_DELETE_ASSET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_STORAGES_PLAYLIST_REQUEST]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_STORAGES_PLAYLIST_REQUEST);

      axios({
        url: params.url,
        method: "GET",
        responseType: "blob"
      })
        .then((response) => {
          commit(constants.TEAM_STORAGES_PLAYLIST_REQUEST_SUCCESS, {
            response
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_STORAGES_PLAYLIST_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_STORAGES_DOWNLOAD_ASSET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_STORAGES_DOWNLOAD_ASSET);

      axios({
        url: params.url_download,
        method: "GET",
        responseType: "blob"
      })
        .then((response) => {
          commit(constants.TEAM_STORAGES_DOWNLOAD_ASSET_SUCCESS, {
            response,
            params
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_STORAGES_DOWNLOAD_ASSET_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.TEAM_STORAGES_REQUEST]: (state) => {
    state.team.storage.status = "fetching";
    state.team.storage.errors = {};
  },
  [constants.TEAM_STORAGES_REQUEST_SUCCESS]: (state, { data }) => {
    state.team.storage.status = "success";
    state.team.storage.errors = {};
    state.team.storage.data = data.results;
  },
  [constants.TEAM_STORAGES_REQUEST_ERROR]: (state, err) => {
    state.team.storage.status = "error";
    state.team.storage.errors = err.response ? err.response.data : err;
  },
  [constants.TEAM_STORAGES_GET_ASSET]: (state) => {
    state.team.storage.asset.status = "fetching";
    state.team.storage.asset.errors = {};
  },
  [constants.TEAM_STORAGES_GET_ASSET_SUCCESS]: (state, response) => {
    state.team.storage.asset.status = "success";
    state.team.storage.asset.errors = {};
    state.team.storage.asset.data = response.data;
    state.team.storage.data.uid = response.storage_uid;
  },
  [constants.TEAM_STORAGES_GET_ASSET_ERROR]: (state, err) => {
    state.team.storage.asset.status = "error";
    state.team.storage.asset.errors = err.response.data;
  },
  [constants.TEAM_STORAGES_SET_ASSET]: (state, asset) => {
    state.team.storage.asset.status = "success";
    state.team.storage.asset.errors = {};
    state.team.storage.asset.data = asset;
    state.team.storage.data.uid = asset.source_storage_id || asset?.storage_id;
  },
  [constants.TEAM_STORAGES_GET_LOCAL_ASSET]: (state) => {
    state.team.localStorage.asset.status = "fetching";
    state.team.localStorage.asset.errors = {};
    state.team.localStorage.asset.data = {};
  },
  [constants.TEAM_STORAGES_GET_LOCAL_ASSET_SUCCESS]: (state, response) => {
    state.team.localStorage.asset.status = "success";
    state.team.localStorage.asset.errors = {};
    state.team.localStorage.asset.data = response.data;
    state.team.localStorage.data.uid = response.storage_uid;
  },
  [constants.TEAM_STORAGES_GET_LOCAL_ASSET_ERROR]: (state, err) => {
    state.team.localStorage.asset.status = "error";
    state.team.localStorage.asset.errors = err.response.data;
    state.team.localStorage.asset.data = {};
  },
  [constants.TEAM_STORAGES_CLEAR_LOCAL_ASSET]: (state) => {
    state.team.localStorage.asset.status = "";
    state.team.localStorage.asset.errors = {};
    state.team.localStorage.asset.data = {};
    state.team.localStorage.data.uid = "";
  },
  [constants.TEAM_STORAGES_PATCH_ASSET]: (state) => {
    state.team.storage.asset.status = "fetching";
    state.team.storage.asset.errors = {};
    state.team.storage.asset.data = {};
  },
  [constants.TEAM_STORAGES_PATCH_ASSET_SUCCESS]: (
    state,
    { payload, rootState }
  ) => {
    state.team.storage.asset.status = "success";
    state.team.storage.asset.errors = {};

    const foundIndex = rootState.flow.logList.data.results.findIndex(
      (x) => x.uid == payload.flowlog_uid
    );
    rootState.flow.logList.data.results.splice(foundIndex, 1);
  },
  [constants.TEAM_STORAGES_PATCH_ASSET_ERROR]: (state, err) => {
    state.team.storage.asset.status = "error";
    state.team.storage.asset.errors = err.response.data;
    state.team.storage.asset.data = {};
  },
  [constants.TEAM_STORAGES_DELETE_ASSET]: (state) => {
    state.team.storage.asset.status = "fetching";
  },
  [constants.TEAM_STORAGES_DELETE_ASSET_SUCCESS]: (
    state,
    { payload, rootState }
  ) => {
    state.team.storage.asset.status = "success";

    const foundIndex = rootState.flow.logList.data.results.findIndex(
      (x) => x.uid == payload.flowlog_uid
    );
    rootState.flow.logList.data.results.splice(foundIndex, 1);
  },
  [constants.TEAM_STORAGES_DELETE_ASSET_ERROR]: (state, err) => {
    state.team.storage.asset.status = "error";
    state.team.storage.asset.errors = err.response.data;
    state.team.storage.asset.data = {};
  },
  [constants.TEAM_STORAGES_PLAYLIST_REQUEST]: (state) => {
    state.team.storage.asset.playlist.status = "fetching";
    state.team.storage.asset.playlist.errors = {};
    state.team.storage.asset.playlist.data = {};
  },
  [constants.TEAM_STORAGES_PLAYLIST_REQUEST_SUCCESS]: (state, { response }) => {
    state.team.storage.asset.playlist.status = "success";
    state.team.storage.asset.playlist.errors = {};

    const blob = new Blob([response.data], { type: "text/xml" });
    const link = document.createElement("a");
    const filename = response.config.url.split(/[/]+/).pop().split(".")[0];
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}.xspf`;
    link.click();
  },
  [constants.TEAM_STORAGES_PLAYLIST_REQUEST_ERROR]: (state, err) => {
    state.team.storage.asset.playlist.status = "error";
    state.team.storage.asset.playlist.errors = err.response
      ? err.response.data
      : err;
  },
  [constants.TEAM_STORAGES_DOWNLOAD_ASSET]: (state) => {
    state.team.storage.asset.download.status = "fetching";
    state.team.storage.asset.download.errors = {};
    state.team.storage.asset.download.data = {};
  },
  [constants.TEAM_STORAGES_DOWNLOAD_ASSET_SUCCESS]: (
    state,
    { response, params }
  ) => {
    state.team.storage.asset.download.status = "success";
    state.team.storage.asset.download.errors = {};
    state.team.storage.asset.download.data = response.data;

    const type = `${params.file_type}/${params.ext}`;

    const blob = new Blob([response.data], { type });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = params.name;
    link.click();
  },
  [constants.TEAM_STORAGES_DOWNLOAD_ASSET_ERROR]: (state, err) => {
    state.team.storage.asset.download.status = "error";
    state.team.storage.asset.download.errors = err.response
      ? err.response.data
      : err;
  },
  [constants.TEAM_STORAGES_SET_DETAIL_TABINDEX]: (state, tabData) => {
    state.team.localStorage.asset.tabIndex = tabData["tabIndex"];
    state.team.localStorage.asset.tabTitle = tabData["tabTitle"];
    state.team.storage.asset.tabIndex = tabData["tabIndex"];
    state.team.storage.asset.tabTitle = tabData["tabTitle"];
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
