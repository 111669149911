<template>
  <div v-if="displayRegistration" class="rc-auth-status-bar">
    <template v-if="!teamEnabled">
      <template v-if="!profile.domain_approved">
        <ul>
          <li>{{ $t("auth.status.signed_in_as_a_guest") }}</li>
          <li>{{ $t("auth.status.domain_is_pending") }}</li>
        </ul>
      </template>
    </template>
    <template v-else>
      <template v-if="organisationIsNew">
        <ul>
          <li>{{ $t("auth.status.signed_in_as_a_guest") }}</li>
          <li>
            <router-link
              :to="{
                name: 'user.account.team.join',
                params: { locale: $i18n.locale }
              }"
              >{{ $t("auth.status.complete_registration") }}</router-link
            >
            <span>&nbsp;{{ $t("auth.status.full_access") }}</span>
          </li>
        </ul>
      </template>

      <template v-if="organisationIsPending">
        <ul>
          <li>{{ $t("auth.status.signed_in_as_a_guest") }}</li>
          <li>
            <span>
              {{ $t("auth.status.org_is_pending") }}
            </span>
          </li>
        </ul>
      </template>

      <template v-if="organisationIsApproved && !userIsInATeam">
        <ul>
          <li>{{ $t("auth.status.signed_in_as_a_guest") }}</li>

          <li>
            <router-link
              :to="{
                name: 'user.account.team.join',
                params: { locale: $i18n.locale }
              }"
              >{{ $t("auth.status.join_a_team") }}</router-link
            >
            <!-- <span>&nbsp; {{ $t("auth.status.or") }} &nbsp;</span>

            <router-link
              :to="{
                name: 'user.account.team.join',
                params: { locale: $i18n.locale }
              }"
              >{{ $t("auth.status.create_a_new_one") }}</router-link
            > -->
            <span>&nbsp; {{ $t("auth.status.full_access") }}</span>
          </li>
        </ul>
      </template>
    </template>
  </div>
</template>

<script>
import Config from "#/config";
import { mapGetters, mapState } from "vuex";
export default {
  name: "RcStatusBanner",
  computed: {
    ...mapState({
      profile: (state) => state.auth.user,
      teamProfile: (state) => state.team.profile
    }),
    ...mapGetters(["isAuthenticated"]),
    organisationIsApproved() {
      return this.profile.organisation_status === "approved";
    },
    organisationIsNew() {
      return this.profile.organisation_status === "new";
    },
    organisationIsPending() {
      return this.profile.organisation_status === "pending";
    },
    userIsInATeam() {
      const teamFilter = this.teamProfile.memberships.filter(
        (m) => m.status === "approved"
      );
      return teamFilter.length ? true : false;
    },
    teamEnabled() {
      return Config.team.enabled;
    },
    displayRegistration() {
      return (
        this.isAuthenticated &&
        ((!this.profile.domain_approved &&
          !this.profile.role == "user" &&
          !this.organisationIsApproved) ||
          this.organisationIsNew ||
          this.organisationIspending ||
          (this.organisationIsApproved && !this.userIsInATeam))
      );
    }
  }
};
</script>

<style lang="scss">
//Properties

:root {
  --rc-auth-status-bar-height: 38px;
}

.rc-auth-status-bar {
  --component-background-color: #000;
  --component-text-color: #fff;
  --component-font-size: #{$small-font-size};
  --component-line-height: calc(var(--component-font-size) * 1.3);
  --component-height: auto;
  --component-padding-x: 16px;
  --component-padding-y: 8px;
  --component-link-color: #fff;
  --component-link-hover-color: var(--component-link-color);

  @media (min-width: $screen-md-min) {
    --component-height: var(--rc-auth-status-bar-height);
  }
}

// Styles

.rc-auth-status-bar {
  position: relative;
  height: var(--component-height);
  padding: var(--component-padding-y) var(--component-padding-x);
  background-color: var(--component-background-color);
  color: var(--component-text-color);
  font-size: var(--component-font-size);
  line-height: var(--component-line-height);

  ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  a {
    text-decoration: underline;
    color: var(--component-link-color);
    &:hover {
      color: var(--component-link-color-hover);
    }
  }
}

@media (min-width: $screen-md-min) {
  .rc-auth-status-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 16;
    width: 100%;

    ul {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }
  }

  //needed for sticky
  .rc-wrapper:not(.rc-playlist-detail-layout) {
    overflow: unset !important;
  }
}
</style>
