<template>
  <div class="rc-wrapper rc-dashboard-layout">
    <rc-skip-nav/>
    <RcAuthStatusBanner />
    <RcSiteHeader />
    <div class="main-container">
      <rc-site-content/>
    </div>
    <rc-notifications />
  </div>
</template>

<script>
import RcSkipNav from "@/libvue/components/skipnav/SkipNav.vue";
import RcSiteContent from "#/layout/components/SiteContent.vue";
import RcSiteHeader from "#/layout/components/SiteHeader.vue";
import RcAuthStatusBanner from "@/libvue/apps/auth/components/StatusBanner.vue";

export default {
  name: "DashbordLayout",
  components: {
    RcSkipNav,
    RcSiteContent,
    RcSiteHeader,
    RcAuthStatusBanner
  }
};
</script>
