var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rc-wrapper rc-util-layout"},[_c('rc-skip-nav'),_c('rc-auth-status-banner'),_c('rc-site-header',{class:{
      disabled:
        this.$route.meta.layoutOptions &&
        this.$route.meta.layoutOptions.disableHeader
    }}),_c('div',{staticClass:"main-container"},[_c('rc-site-content')],1),(
      !this.$route.meta.layoutOptions ||
        !this.$route.meta.layoutOptions.hideFooter
    )?_c('rc-site-footer'):_vm._e(),_c('rc-notifications')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }