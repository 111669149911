// TEAM STORAGES
export const TEAM_STORAGES_REQUEST = "TEAM_STORAGES_REQUEST";
export const TEAM_STORAGES_REQUEST_SUCCESS = "TEAM_STORAGES_REQUEST_SUCCESS";
export const TEAM_STORAGES_REQUEST_ERROR = "TEAM_STORAGES_REQUEST_ERROR";

export const TEAM_STORAGES_GET_ASSET = "TEAM_STORAGES_GET_ASSET";
export const TEAM_STORAGES_GET_ASSET_SUCCESS =
  "TEAM_STORAGES_GET_ASSET_SUCCESS";
export const TEAM_STORAGES_GET_ASSET_ERROR = "TEAM_STORAGES_GET_ASSET_ERROR";

export const TEAM_STORAGES_SET_ASSET = "TEAM_STORAGES_SET_ASSET";

export const TEAM_STORAGES_GET_LOCAL_ASSET = "TEAM_STORAGES_GET_LOCAL_ASSET";
export const TEAM_STORAGES_GET_LOCAL_ASSET_SUCCESS =
  "TEAM_STORAGES_GET_LOCAL_ASSET_SUCCESS";
export const TEAM_STORAGES_GET_LOCAL_ASSET_ERROR =
  "TEAM_STORAGES_GET_LOCAL_ASSET_ERROR";

export const TEAM_STORAGES_CLEAR_LOCAL_ASSET =
  "TEAM_STORAGES_CLEAR_LOCAL_ASSET";

export const TEAM_STORAGES_PATCH_ASSET = "TEAM_STORAGES_PATCH_ASSET";
export const TEAM_STORAGES_PATCH_ASSET_SUCCESS =
  "TEAM_STORAGES_PATCH_ASSET_SUCCESS";
export const TEAM_STORAGES_PATCH_ASSET_ERROR =
  "TEAM_STORAGES_PATCH_ASSET_ERROR";

export const TEAM_STORAGES_DELETE_ASSET = "TEAM_STORAGES_DELETE_ASSET";
export const TEAM_STORAGES_DELETE_ASSET_SUCCESS =
  "TEAM_STORAGES_DELETE_ASSET_SUCCESS";
export const TEAM_STORAGES_DELETE_ASSET_ERROR =
  "TEAM_STORAGES_DELETE_ASSET_ERROR";

export const TEAM_STORAGES_PLAYLIST_REQUEST = "TEAM_STORAGES_PLAYLIST_REQUEST";
export const TEAM_STORAGES_PLAYLIST_REQUEST_SUCCESS =
  "TEAM_STORAGES_PLAYLIST_REQUEST_SUCCESS";
export const TEAM_STORAGES_PLAYLIST_REQUEST_ERROR =
  "TEAM_STORAGES_PLAYLIST_REQUEST_ERROR";

export const TEAM_STORAGES_SET_DETAIL_TABINDEX =
  "TEAM_STORAGES_SET_DETAIL_TABINDEX";
export const TEAM_STORAGES_SET_DETAIL_TABINDEX_SUCCESS =
  "TEAM_STORAGES_SET_DETAIL_TABINDEX_SUCCESS";
export const TEAM_STORAGES_SET_DETAIL_TABINDEX_ERROR =
  "TEAM_STORAGES_SET_DETAIL_TABINDEX_ERROR";

export const TEAM_STORAGES_DOWNLOAD_ASSET = "TEAM_STORAGES_DOWNLOAD_ASSET";
export const TEAM_STORAGES_DOWNLOAD_ASSET_SUCCESS =
  "TEAM_STORAGES_DOWNLOAD_ASSET_SUCCESS";
export const TEAM_STORAGES_DOWNLOAD_ASSET_ERROR =
  "TEAM_STORAGES_DOWNLOAD_ASSET_ERROR";
