<template>
  <div class="rc-section-background default">
    <img
      :src="imageUrl"
      class="default-background initial loading"
      data-was-processed="true"
    />
  </div>
</template>


<script>
export default {
  name: "SiteBackground",
  props: {
    imageUrl: {
      type: String,
      default: "/img/rc-process-bg.jpg"
    }
  }
};
</script>
