import Vue from 'vue';
import Vuex from 'vuex';

// root store
import root from './root';

// libvue store, dynamic state for shared accross multiple components
import libvue from '@/libvue/components/store';

// components store
import modal from '@/libvue/components/modal/store';

// apps store
import auth from '@/libvue/apps/auth/store';
import team from '@/libvue/apps/team/store';
import user from '@/libvue/apps/user/store';
import flow from '@/libvue/apps/flow/store';
import archives from '@/libvue/apps/archives/store';
import deliveries from '@/libvue/apps/deliveries/store';
import upload from '@/libvue/apps/upload/store';
import storage from '@/libvue/apps/storage/store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    root,
    // libvue
    libvue,
    // components
    modal,
    // apps
    auth,
    team,
    user,
    flow,
    archives,
    deliveries,
    upload,
    storage,
  },
  strict: debug,
});
