export const ROOT_UPDATE_PAGE_LOADED = "ROOT_UPDATE_PAGE_LOADED";
export const ROOT_RESET_ALL_STATES = "ROOT_RESET_ALL_STATES";
export const ROOT_SHOW_CONFIRMATION_MODAL = "ROOT_SHOW_CONFIRMATION_MODAL";
export const ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE =
  "ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE";
export const ROOT_SHOW_MOBILE_MENU = "ROOT_SHOW_MOBILE_MENU";
export const ROOT_HIDE_MOBILE_MENU = "ROOT_HIDE_MOBILE_MENU";
export const ROOT_UPDATE_IF_TOUCH_DEVICE = "ROOT_UPDATE_IF_TOUCH_DEVICE";

import { AUTH_RESET } from "@/libvue/apps/auth/actions";
import { TEAM_RESET } from "@/libvue/apps/team/actions";
import { MODAL_RESET, MODAL_OPEN } from "@/libvue/components/modal/actions";

const defaultState = {
  errors: {},
  status: "",
  isPageLoaded: false,
  isPreviewPaneOpenable: true,
  isMobileMenuVisible: false,
  isUserOnATouchDevice: false
};

const actions = {
  [ROOT_UPDATE_PAGE_LOADED]: ({ commit }, isPageLoaded) => {
    commit(ROOT_UPDATE_PAGE_LOADED, isPageLoaded);
  },
  [ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE]: (
    { commit },
    isPreviewPaneOpenable
  ) => {
    commit(ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE, isPreviewPaneOpenable);
  },
  [ROOT_RESET_ALL_STATES]: ({ commit }) => {
    commit(AUTH_RESET);
    commit(TEAM_RESET);
    commit(MODAL_RESET);
  },
  [ROOT_SHOW_CONFIRMATION_MODAL]: ({ dispatch }) => {
    dispatch(MODAL_OPEN, { id: "confirmationModal" });
  },
  [ROOT_SHOW_MOBILE_MENU]: ({ commit }) => {
    commit(ROOT_SHOW_MOBILE_MENU);
  },
  [ROOT_HIDE_MOBILE_MENU]: ({ commit }) => {
    commit(ROOT_HIDE_MOBILE_MENU);
  },
  [ROOT_UPDATE_IF_TOUCH_DEVICE]: ({ commit }, isUserOnATouchDevice) => {
    commit(ROOT_UPDATE_IF_TOUCH_DEVICE, isUserOnATouchDevice);
  }
};

const mutations = {
  [ROOT_UPDATE_PAGE_LOADED]: (state, isPageLoaded) => {
    state.isPageLoaded = isPageLoaded;
  },
  [ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE]: (state, isPreviewPaneOpenable) => {
    state.isPreviewPaneOpenable = isPreviewPaneOpenable;
  },
  [ROOT_SHOW_MOBILE_MENU]: (state) => {
    state.isMobileMenuVisible = true;
  },
  [ROOT_HIDE_MOBILE_MENU]: (state) => {
    state.isMobileMenuVisible = false;
  },
  [ROOT_UPDATE_IF_TOUCH_DEVICE]: (state, isUserOnATouchDevice) => {
    state.isUserOnATouchDevice = isUserOnATouchDevice;
  }
};

export default {
  state: defaultState,
  actions,
  mutations
};
