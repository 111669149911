<template>
  <b-modal :id="modalId" :title="$t('user.edit_profile')" @show="resetForm()">
    <template #modal-footer="{ cancel }">
      <rc-btn variant="secondary" @click="cancel()">{{
        $t(`common.cancel`)
      }}</rc-btn>
      <rc-btn size="lg" variant="primary" @click="handleOk()">{{
        $t(`common.save`)
      }}</rc-btn>
    </template>
    <RcForm :form="form"></RcForm>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RcForm from "@/libvue/components/form/Form.vue";
import { USER_SET_PROFILE_REQUEST } from "@/libvue/apps/user/actions";
import { formValidationMixin } from "@/libvue/components/form/mixins";

export default {
  name: "EditProfileModal",

  components: {
    RcForm
  },
  mixins: [formValidationMixin],
  data() {
    return {
      errors: {},
      modalId: "edit-profile-modal",
      formState: true
    };
  },
  computed: {
    form() {
      return {
        fields: [
          {
            name: "full_name",
            value: "",
            required: true,
            disabled: false,
            type: "text",
            label: this.$t("common.name"),
            initial: "",
            validate: {
              active: true,
              isValid: null,
              message: "Please provide your name",
              blured: false,
              maxLength: 60
            }
          },
          {
            name: "phone_number",
            value: "",
            required: false,
            disabled: false,
            type: "tel",
            label: this.$t("auth.phone_number"),
            initial: "",
            validate: {
              active: true,
              isValid: null,
              message: "Please provide a valid phone number",
              maxLength: 15,
              blured: false,
              helpText: this.$t("auth.phone_number_help_text")
            }
          }
        ]
      };
    },
    ...mapState({
      profile: (state) => state.auth.user
    })
  },
  created() {
    this.form.fields.forEach((f) => {
      if (f.name == "full_name") {
        f.initial = this.profile.full_name;
      } else if (f.name == "phone_number") {
        f.initial = this.profile.phone_number ? this.profile.phone_number : "";
      }
    });
  },
  methods: {
    ...mapActions({
      patch: USER_SET_PROFILE_REQUEST
    }),

    handleOk() {
      if (this.validateAll()) {
        this.patch(this.formatPayload())
          .then(() => {
            this.$bvModal.hide(this.modalId);
          })
          .catch(() => {
            this.$bvModal.hide(this.modalId);
          });
      }
    }
  }
};
</script>
