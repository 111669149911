// Global components
import Vue from "vue";
import Default from "./Default.vue";
import Home from "./Home.vue";
import Landing from "./Landing.vue";
import Detail from "./Detail.vue";
import Account from "./Account.vue";
import DetailPlaylist from "./DetailPlaylist";
import Register from "./Register";
import Util from "./Util";
import Plain from "./Plain";
import Dashboard from "./Dashboard";

Vue.component("default-layout", Default);
Vue.component("home-layout", Home);
Vue.component("landing-layout", Landing);
Vue.component("detail-layout", Detail);
Vue.component("account-layout", Account);
Vue.component("detail-playlist-layout", DetailPlaylist);
Vue.component("register-layout", Register);
Vue.component("util-layout", Util);
Vue.component("plain-layout", Plain);
Vue.component("dashboard-layout", Dashboard);

