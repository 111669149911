import app from "#/app";
import store from "#/stores";
import Config from "#/config";
import i18n from "#/locales";

export function ifNotAuthenticated(to, from, next) {
  if (!store.getters.isAuthenticated || !store.getters.hasAcceptedAgreements) {
    next();
    return;
  }
  next("/");
}

export function ifAuthenticated(to, from, next) {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next({ name: "auth.login", query: { redirect: to.fullPath } });
}

export function ifUserKnown(to, from, next) {
  if (store.getters.isAuthenticated) {
    const redirect = {
      ...redirectByRole(store.state.auth.user),
      params: { locale: i18n.locale }
    };
    app.$Progress.finish();
    next(redirect);
  } else {
    next();
  }
}

export function ifUserInTeam(to, from, next) {
  if (store.state.auth.user.team_uid) {
    next();
  } else {
    next({ name: "user.account.team.join" });
  }
}

export function redirectByRole(auth) {
  const redirect = Config.auth.redirects.afterLoginSuccess[auth.role]
    ? Config.auth.redirects.afterLoginSuccess[auth.role]
    : Config.auth.redirects.afterLoginSuccess["default"];

  return redirect;
}
