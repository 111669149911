import axios from "@/utils/axios.js";

import * as constants from "./actions";

function getDefaultState() {
  return {
    aggs: {},
    detail: { view: "rc-archive-summary", displayName: "Asset detail" },
    status: "",
    errors: "",
    archives: {
      pagination: {},
      data: [],
      status: "",
      errors: "",
      emptyFeedback: "There is no archives corresponding to this query"
    },
    assets: {
      pagination: {},
      data: [],
      status: "",
      errors: "",
      emptyFeedback: "There is no archives corresponding to this query"
    },
    asset: {
      data: {},
      status: "",
      errors: "",
      emptyFeedback: "There is no archives corresponding to this query"
    },
    sentAssets: {
      data: {},
      status: "",
      errors: ""
    }
  };
}

function getFilterQuery(params) {
  let url = [];
  Object.keys(params).forEach((key) => {
    if (params[key] && params[key].value) {
      url.push(params[key].value);
    }
  });
  return url.join("&");
}

const getters = {
  archiveFacets: (state) => {
    const drv = {
      archive_name: [],
      archive_ext: [],
      archive_has_identify: [],
      archived_on: []
    };

    state.aggs._filter_archive_name
      ? state.aggs._filter_archive_name.archive_name.buckets.forEach((item) => {
          drv.archive_name.push({
            text: item.key,
            value: `archive_name=${item.key}`,
            count: item.doc_count
          });
        })
      : [];

    state.aggs._filter_ext
      ? state.aggs._filter_ext.ext.buckets.forEach((item) => {
          drv.archive_ext.push({
            text: item.key,
            value: `ext=${item.key}`,
            count: item.doc_count
          });
        })
      : [];

    state.aggs._filter_archived_on
      ? state.aggs._filter_archived_on.archived_on.buckets.forEach((item) => {
          drv.archived_on.push({
            text: item.key,
            value: `archived_on=${item.key}`,
            count: item.doc_count
          });
        })
      : [];

    state.aggs._filter_has_identify
      ? state.aggs._filter_has_identify.has_identify.buckets.forEach((item) => {
          drv.archive_has_identify.push({
            text: item.key ? "Identified" : "Unknown",
            value: `has_identify=${item.key_as_string}`,
            count: item.doc_count
          });
        })
      : [];

    return drv;
  }
};

const actions = {
  [constants.TEAM_ARCHIVES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_ARCHIVES_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      axios
        .get(`teams/${team_uid}/archives`)
        .then((response) => {
          commit(constants.TEAM_ARCHIVES_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_ARCHIVES_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_ARCHIVES_SEARCH_ASSETS]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_ARCHIVES_SEARCH_ASSETS);

      const team_uid = rootState.auth.user.team_uid;
      const url = `teams/${team_uid}/archives/get-assets`;
      const searchQuery = rootState.libvue.archives.search.value
        ? rootState.libvue.archives.search.value.value
        : "";
      const filters = rootState.libvue.archives.filters;
      const filterQuery = getFilterQuery(filters);

      axios
        .get(`${url}?${filterQuery}&${searchQuery}`)
        .then((response) => {
          commit(constants.TEAM_ARCHIVES_SEARCH_ASSETS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_ARCHIVES_SEARCH_ASSETS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_ARCHIVES_AGGS_REQUEST]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_ARCHIVES_AGGS_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      const searchQuery = rootState.libvue.archives.search.value
        ? rootState.libvue.archives.search.value.value
        : "";
      const filters = rootState.libvue.archives.filters;
      const filterQuery = getFilterQuery(filters);
      const url = `teams/${team_uid}/archives/get-assets`;
      const re = new RegExp(params, "g");
      const aggsFilter = filterQuery.replace(re, "");

      axios
        .get(`${url}?limit=0&${aggsFilter}&${searchQuery}`)
        .then((response) => {
          commit(constants.TEAM_ARCHIVES_AGGS_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_ARCHIVES_AGGS_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.ARCHIVES_SEARCH_SET_ORDER_BY]: ({ commit, dispatch }, orderBy) =>
    new Promise((resolve, reject) => {
      commit(constants.ARCHIVES_SEARCH_SET_ORDER_BY, orderBy);
      dispatch(constants.TEAM_ARCHIVES_SEARCH_ASSETS)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    }),
  [constants.TEAM_ARCHIVES_SEND_TO_REGULATOR]: (
    { commit, rootState },
    payload
  ) => {
    const team_uid = rootState.auth.user.team_uid;
    const regulator_uid = payload.uid;
    const body = payload.body;

    return new Promise((resolve, reject) => {
      commit(constants.TEAM_ARCHIVES_SEND_TO_REGULATOR);
      axios
        .post(
          `teams/${team_uid}/regulators/${regulator_uid}/get-remote-assets`,
          body
        )
        .then((response) => {
          commit(constants.TEAM_ARCHIVES_SEND_TO_REGULATOR_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_ARCHIVES_SEND_TO_REGULATOR_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.TEAM_ARCHIVES_APPEND_NEXT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_ARCHIVES_APPEND_NEXT);

      const url = params.next ? params.next : "";

      axios
        .get(url)
        .then((response) => {
          commit(constants.TEAM_ARCHIVES_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_ARCHIVES_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.TEAM_ARCHIVES_REQUEST]: (state) => {
    state.archives.status = "fetching";
    state.archives.errors = {};
  },
  [constants.TEAM_ARCHIVES_REQUEST_SUCCESS]: (state, { data }) => {
    state.archives.status = "success";
    state.archives.errors = {};
    state.archives.data = data.results;
  },
  [constants.TEAM_ARCHIVES_REQUEST_ERROR]: (state, err) => {
    state.archives.status = "error";
    state.archives.errors = err.response ? err.response.data : err;
  },
  [constants.TEAM_ARCHIVES_SEARCH_ASSETS]: (state) => {
    state.assets.status = "fetching";
    state.assets.errors = {};
    state.assets.data = [];
    state.assets.pagination = {};
  },
  [constants.TEAM_ARCHIVES_SEARCH_ASSETS_SUCCESS]: (state, { data }) => {
    state.aggs = data.aggregations;
    state.assets.status = "success";
    state.assets.errors = {};
    state.assets.data = data.results;
    state.assets.pagination = {
      count: data.count,
      next: data.next,
      page_size: data.page_size
    };
  },
  [constants.TEAM_ARCHIVES_SEARCH_ASSETS_ERROR]: (state, err) => {
    state.assets.status = "error";
    state.assets.errors = err.response ? err.response.data : err;
  },
  [constants.TEAM_ARCHIVES_AGGS_REQUEST]: (state) => {
    state.assets.status = "fetching";
  },
  [constants.TEAM_ARCHIVES_AGGS_REQUEST_SUCCESS]: (state, { data }) => {
    state.aggs = data.aggregations;
    state.assets.status = "success";
  },
  [constants.TEAM_ARCHIVES_AGGS_REQUEST_ERROR]: (state, err) => {
    state.assets.status = "error";
    state.assets.errors = err.response ? err.response.data : err;
  },
  [constants.ARCHIVES_ASSET_SET_SELECTED]: (state, asset) => {
    state.status = `success`;
    state.errors = {};
    state.asset.data = asset;
  },
  [constants.ARCHIVES_SEARCH_SET_ORDER_BY]: (state, orderBy) => {
    state.orderBy = orderBy;
  },
  [constants.ARCHIVES_ASSET_SET_VIEW_COMPONENT]: (state, detail) => {
    state.status = `success`;
    state.errors = {};
    state.detail = detail;
  },
  [constants.TEAM_ARCHIVES_SEND_TO_REGULATOR]: (state) => {
    state.sentAssets.status = "fetching";
    state.sentAssets.errors = {};
  },
  [constants.TEAM_ARCHIVES_SEND_TO_REGULATOR_SUCCESS]: (state, { data }) => {
    state.sentAssets.status = "success";
    state.sentAssets.errors = {};
    state.sentAssets.data = data.results;
  },
  [constants.TEAM_ARCHIVES_SEND_TO_REGULATOR_ERROR]: (state, err) => {
    state.sentAssets.status = "error";
    state.sentAssets.errors = err;
  },
  [constants.TEAM_ARCHIVES_APPEND_NEXT]: (state) => {
    state.archives.status = `fetching`;
    state.errors = {};
  },
  [constants.TEAM_ARCHIVES_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.aggs = data.aggregations;
    state.assets.status = `success`;
    state.assets.errors = {};
    state.assets.data = [...state.assets.data, ...data.results];
    state.assets.pagination = {
      count: data.count,
      next: data.next,
      page_size: data.page_size
    };
  },
  [constants.TEAM_ARCHIVES_APPEND_NEXT_ERROR]: (state, err) => {
    state.assets.status = `error`;
    state.assets.errors = err.response ? err.response.data : err;
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
