<template>
  <header class="site-header" ref="siteHeader" @blur="removeTabIndex">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand">
        <rc-btn
          variant="link"
          aria-label="Menu"
          class="rc-sidenav-trigger"
          @click="toggleSideNav"
        ></rc-btn>

        <div v-if="$route.name != 'home.user'">
          <router-link
            :to="{ name: 'home.user', params: { locale: $i18n.locale } }"
            class="navbar-brand router-link-active"
            >Conduit</router-link
          >
        </div>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item" v-if="userIsInATeam && userCanReadUploads">
            <button
              class="btn btn-transparent px-0 py-0 mr-2"
              @click="onActivityClick()"
            >
              <span
                class="rc-avatar rc-avatar-with-badge bg-light text-dark rounded-circle"
                style="height: 36px; width: 36px"
              >
                <RcIcon name="activity"></RcIcon>
                <div
                  class="rc-avatar-badge rounded-circle"
                  :class="'bg-' + uploadProgress.variant"
                ></div>
              </span>
            </button>
          </li>

          <b-nav-item-dropdown
            v-if="isAuthenticated"
            right
            no-caret
            class="rc-user-trigger"
          >
            <template #button-content>User options</template>

            <b-dropdown-item
              :to="{
                name: 'user.account.team.join',
                params: { locale: $i18n.locale }
              }"
              >Teams</b-dropdown-item
            >
            <!-- <b-dropdown-item v-b-modal.root-upload-modal
              >Uploads &amp; progress</b-dropdown-item
            > -->
            <template>
              <b-dropdown-item :href="apiAdminUrl" target="_blank"
                >Admin</b-dropdown-item
              >
            </template>
            <b-dropdown-divider />

            <b-dropdown-item role="button" @click="logoutHandler()"
              >Sign out</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            v-if="isAuthenticated"
            class="rc-team-trigger d-none d-md-block rc-container-overflow"
            right
            no-caret
            menu-class="rc-content-scroll"
          >
            <template v-if="memberships.length" slot="button-content">
              <span>Team</span>
              {{ profile.team_name }}
            </template>

            <template v-else slot="button-content">
              <span>Team</span>
              <div class="rc-loading-message">Loading teams</div>
            </template>

            <RcJoinedTeams></RcJoinedTeams>
          </b-nav-item-dropdown>

          <li v-else class="nav-item rc-signin-trigger">
            <router-link
              href="/en/login"
              :to="{ name: 'auth.login' }"
              class="nav-link router-link-exact-active router-link-active"
              >Sign in</router-link
            >
          </li>
        </ul>
      </nav>

      <div
        class="rc-sidenav"
        ref="sidenavPanel"
        @blur="removeTabIndex"
        @transitionend.self="hideAfterNavClose"
      >
        <rc-btn
          variant="link"
          class="rc-sidenav-close"
          aria-label="Close"
          @click="toggleSideNav"
        ></rc-btn>

        <div class="list-group">
          <router-link
            class="list-group-item list-group-item-action"
            :class="routeIsActive('home.user') ? 'active' : ''"
            :to="{ name: 'home.user', params: { locale: $i18n.locale } }"
          >
            <span>Home</span>
          </router-link>

          <router-link
            v-if="userIsInATeam"
            class="list-group-item list-group-item-action"
            :class="routeIsActive('flow') ? 'active' : ''"
            :to="{ name: 'flow.list', params: { locale: $i18n.locale } }"
          >
            <span>Flow</span> Monitor and track your flow
          </router-link>
          <router-link
            v-if="userIsInATeam"
            class="list-group-item list-group-item-action"
            :class="routeIsActive('archives') ? 'active' : ''"
            :to="{ name: 'archives.list', params: { locale: $i18n.locale } }"
          >
            <span>Archive</span> Search and find archived files
          </router-link>
          <router-link
            v-if="userIsInATeam"
            class="list-group-item list-group-item-action"
            :class="routeIsActive('deliveries') ? 'active' : ''"
            :to="{ name: 'deliveries.list', params: { locale: $i18n.locale } }"
          >
            <span>Delivery</span> Monitor your deliveries
          </router-link>
          <router-link
            class="list-group-item list-group-item-action"
            v-if="userIsInATeam && userCanReadUploads"
            :class="routeIsActive('upload') ? 'active' : ''"
            :to="{ name: 'upload.list', params: { locale: $i18n.locale } }"
          >
            <span>Upload</span> Upload your own media
          </router-link>

          <a
            class="list-group-item rc-report-link list-group-item-action"
            :href="$t('nav.url.contact_us')"
            >Report bug/feedback
          </a>
        </div>
      </div>

      <div
        class="rc-sidenav-backdrop"
        ref="sidenavBackdrop"
        @click="toggleSideNav"
        @transitionend="hideAfterNavClose"
      ></div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { ROOT_SHOW_MOBILE_MENU, ROOT_HIDE_MOBILE_MENU } from "#/stores/root";
import { AUTH_LOGOUT } from "@/libvue/apps/auth/actions";
import Config from "#/config";
import RcJoinedTeams from "@/libvue/apps/team/components/JoinedTeams.vue";
import RcIcon from "@/libvuePure/components/icons/Base.vue";

import { UPLOADS_SET_PROGRESS } from "@/libvue/apps/upload/actions";

export default {
  name: "SiteHeader",

  components: {
    RcJoinedTeams,
    RcIcon
  },

  data() {
    return {
      sideNavIsOpen: false,
      siteHeader: null,
      sidenavPanel: null,
      sidenavBackdrop: null,
      bodyElement: null,
      navFirstFocusable: null,
      navLastFocusable: null
    };
  },
  computed: {
    apiAdminUrl() {
      return Config.apiAdminUrl();
    },

    userCanWriteSysAdmin() {
      if (this.profile.scopes) {
        return this.$user.can("sysadmin:write", this.profile);
      }
      return false;
    },
    userCanReadUploads() {
      return this.profile.scopes
        ? this.$user.can("uploads:read", this.profile)
        : false;
    },
    userName() {
      return this.profile.full_name || this.profile.email;
    },
    ...mapState({
      isMobileMenuVisible: (state) => state.root.isMobileMenuVisible,
      profile: (state) => state.auth.user,
      categories: (state) => state.catalog.categories,
      memberships: (state) => state.team.profile.memberships,
      currentTeam: (state) => state.team.current.team,
      uploadProgress: (state) => state.upload.progress
    }),
    ...mapGetters({
      isAuthenticated: "isAuthenticated"
    }),
    userIsInATeam() {
      const teamFilter = this.memberships.filter(
        (m) => m.status === "approved"
      );
      return teamFilter.length ? true : false;
    }
  },
  mounted() {
    this.sidenavPanel = this.$refs.sidenavPanel;
    this.sidenavBackdrop = this.$refs.sidenavBackdrop;
    this.siteHeader = this.$refs.siteHeader;
    const navFocusableElements =
      this.sidenavPanel.querySelectorAll("a, button");
    this.navFirstFocusable = navFocusableElements[0];
    this.navLastFocusable =
      navFocusableElements[navFocusableElements.length - 1];
    this.bodyElement = document.querySelector("body");

    document.addEventListener("keydown", this.navKeydownHandler);
  },
  methods: {
    toggleSideNav() {
      this.sideNavIsOpen = !this.sideNavIsOpen;

      if (this.sideNavIsOpen) {
        // Show and open sidenav panel and backdrop
        this.sidenavPanel.classList.add("show", "opened");
        this.sidenavBackdrop.classList.add("show", "opened");

        // When openning nav, move focus to nav so that tabbing will focus on nav elements (for KB navigation)
        this.sidenavPanel.setAttribute("tabindex", "-1");
        this.sidenavPanel.focus();

        // Prevent scrolling on body while nav is opened
        this.bodyElement.classList.add("no-scroll");
      } else {
        // Close sidenav panel and backdrop (show class will be removed on transitionend)
        this.sidenavPanel.classList.remove("opened");
        this.sidenavBackdrop.classList.remove("opened");

        // When closing nav, give focus back to header, where nav was openned (for KB navigation)
        this.siteHeader.setAttribute("tabindex", "-1");
        this.siteHeader.focus();

        // Allow scrolling on body while nav is closed
        this.bodyElement.classList.remove("no-scroll");
      }
    },
    hideAfterNavClose(e) {
      if (!this.sideNavIsOpen) {
        e.target.classList.remove("show");
      }
    },
    removeTabIndex(e) {
      e.target.removeAttribute("tabindex");
    },
    navKeydownHandler(e) {
      e = e || window.event;
      //If nav is open...
      if (this.sideNavIsOpen) {
        // Close it by pressing Esc
        if (e.key === "Escape") {
          this.toggleSideNav();
        }
        // If Shift + Tab is pressed while the first element of nav has focus, give focus to last nav element (focus trap)
        else if (
          e.shiftKey &&
          e.key === "Tab" &&
          this.navFirstFocusable == document.activeElement
        ) {
          e.preventDefault();
          this.navLastFocusable.focus();
        }
        // If Tab is pressed while the last element of nav has focus, give focus to first nav element (focus trap)
        else if (
          !e.shiftKey &&
          e.key === "Tab" &&
          this.navLastFocusable == document.activeElement
        ) {
          e.preventDefault();
          this.navFirstFocusable.focus();
        }
      }
    },
    routeIsActive(routeName) {
      return this.$route.name ? this.$route.name.includes(routeName) : false;
    },
    toggleMenu() {
      this.isMobileMenuVisible ? this.hideMobileMenu() : this.showMobileMenu();
    },
    logoutHandler() {
      this.isMobileMenuVisible && this.hideMobileMenu();
      this.$store.dispatch(AUTH_LOGOUT);
    },
    onActivityClick() {
      if (this.uploadProgress.active) {
        this.$bvModal.show("root-upload-modal");
      } else {
        this.$store.commit(UPLOADS_SET_PROGRESS, {
          reset: true,
          fields: {
            seen: true
          }
        });
        this.$router.push({ name: "upload.list" });
      }
    },

    ...mapActions({
      showMobileMenu: ROOT_SHOW_MOBILE_MENU,
      hideMobileMenu: ROOT_HIDE_MOBILE_MENU
    })
  },
  watch: {
    $route() {
      if (this.sideNavIsOpen) {
        this.toggleSideNav();
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keydownHandler);
    this.bodyElement.classList.remove("no-scroll");
  }
};
</script>

<style lang="scss">
.site-header {
  border-bottom: thin solid #dddddd;
  &:focus {
    outline: none;
  }
  .navbar {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    .rc-sidenav-trigger {
      display: block;
      padding: 0;
      margin-left: -5px;
      background: url(../../assets/img/ic_menu.svg) no-repeat center center
        transparent;
      background-size: 30px 20px;
      width: 40px;
      height: 30px;
    }
    .rc-user-trigger {
      display: flex;
      align-items: center;
      & > .nav-link {
        padding-right: 1.2em !important;
        // &::before {
        //   content: "";
        //   background: url(/img/ic_dropdown.svg) no-repeat center top
        //   transparent;
        //   display: block;
        //   position: absolute;
        //   top: 50%;
        //   right: 0;
        //   margin-top: -7px;
        //   width: 14px;
        //   height: 14px;
        // }
      }
      & + .rc-team-trigger {
        margin-left: 1.5em;
      }
    }
    .rc-team-trigger {
      display: flex;
      align-items: center;
      & > .nav-link {
        padding-right: 1.2em !important;
        span {
          display: block;
          font-size: small;
          font-weight: bold;
        }
        &::before {
          content: "";
          background: url(../../assets/img/ic_dropdown.svg) no-repeat center top
            transparent;
          display: block;
          position: absolute;
          top: 2em;
          right: 0;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.rc-sidenav {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100vh;
  padding-top: 120px;
  background: #343434;
  transform: translateX(-320px);
  z-index: 1040;
  transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:focus {
    outline: none;
  }

  &.opened {
    transform: translateX(0);
  }

  &.show {
    visibility: visible;
  }
  ::v-deep(.list-group-item) {
    border-radius: 0;
    border: 0 none;
    padding: 14px 16px 16px 24px;
    background: none;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.5);
    span {
      display: block;
      font-size: 24px;
      line-height: 1;
      color: #fff;
    }
    &.active {
      padding: 14px 16px 16px 20px;
      background: rgba(255, 255, 255, 0.05);
      border-left: 4px solid var(--primary);
    }
    &.rc-report-link {
      padding-left: 48px;
      background: url(../../assets/img/ic_comment.svg) no-repeat 24px center
        transparent;
      color: #fff;
    }
  }
}

.rc-sidenav-close {
  position: absolute;
  padding: 0;
  top: 25px;
  left: 20px;
  width: 30px;
  height: 30px;
  background: url(../../assets/img/ic_close_white.svg) center center no-repeat
    #343434;
  background-size: 24px;
  &:focus {
    box-shadow: 0 0 0 0.125rem #343434, 0 0 0 calc(0.125rem * 2) #16b3d9 !important;
  }
}

.rc-sidenav-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0;
  transition: opacity 0.6s linear;
  pointer-events: none;

  &.opened {
    opacity: 0.5;
    pointer-events: auto;
  }

  &.show {
    z-index: 1030;
  }
}
</style>
