<template>
    <div class="rc-content-root" v-bind:class="contentClass" id="skip-nav-target">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'SiteContent',
    props: [
      'contentClass'
    ]
};
</script>
