import Vue from "vue";
import Router from "vue-router";
import i18n from "#/locales";

// content pages
import HomeUser from "#/pages/HomeUser.vue";
import NotFound from "#/pages/NotFound.vue";

// apps routes
import AuthRoutes from "./authRoutes";
import TeamRoutes from "./teamRoutes";
import FlowRoutes from "@/libvue/apps/flow/routes";
import ArchivesRoutes from "@/libvue/apps/archives/routes";
import DeliveriesRoutes from "@/libvue/apps/deliveries/routes";
import StorageRoutes from "@/libvue/apps/storage/routes";
import UploadsRoutes from "./upload";

// Needed to load profile before each page if not loaded
import store from "#/stores";
import {
  AUTH_REFRESH,
  AUTH_GET_SESSION_TOKEN,
  AUTH_GET_IP_TOKEN
} from "@/libvue/apps/auth/actions";
import { ROOT_UPDATE_PAGE_LOADED } from "#/stores/root";

import { redirectByRole } from "#/stores/auth";

Vue.use(Router);

function load(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `#/pages/${view}.vue`);
}

const router = new Router({
  mode: "history",
  // Nginx config does not work well with history mode
  routes: [
    {
      path: "/",
      name: "homeNoLang",
      redirect: () => ({
        name: "home.user",
        params: { locale: i18n.locale }
      }),
      component: load("HomeUser"),
      meta: {
        layout: "home"
      }
    },
    {
      path: "/:locale(en)",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "",
          name: "home.user",
          component: HomeUser,
          meta: { layout: "home" }
        },
        {
          path: "register",
          name: "user.register.organisation",
          component: load("Registration")
        },
        ...AuthRoutes,
        ...TeamRoutes,
        FlowRoutes,
        ArchivesRoutes,
        DeliveriesRoutes,
        StorageRoutes,
        UploadsRoutes
      ]
    },
    {
      path: "*",
      name: "404",
      component: NotFound,
      meta: { layout: "plain" }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// Use this to load data upon refresh or login
router.beforeEach((to, from, next) => {
  // set locale from route param
  if (to.params.locale) {
    localStorage.setItem("pref-lang", to.params.locale);
    i18n.locale = to.params.locale;
  }

  let promise = "";
  // refresh token if it exists in local storage
  if (
    !store.getters.getProfile.user_uid &&
    localStorage.getItem("refresh-token")
  ) {
    promise = AUTH_REFRESH;
  }

  if (
    !from.name &&
    !store.getters.getProfile.user_uid &&
    !localStorage.getItem("refresh-token")
  ) {
    promise = AUTH_GET_SESSION_TOKEN;
  }

  promise
    ? store
        .dispatch(promise)
        .then((res) => {
          if (
            res.auth.role == "user" &&
            redirectByRole(res.auth).name !== to.name
          ) {
            const redirect = {
              ...redirectByRole(res.auth),
              params: { locale: i18n.locale },
              query: { redirect: to.fullPath }
            };
            next(redirect);
          }
          next();
        })
        .catch(() => {
          store
            .dispatch(AUTH_GET_IP_TOKEN)
            .then(() => next())
            .catch(() => next());
        })
    : next();
});

router.afterEach(() => {
  store.dispatch(ROOT_UPDATE_PAGE_LOADED, true);
});

export default router;
