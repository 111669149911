const DEVELOPMENT = "development";
const QA = "qa";
const REMOTE = "remote";
const PRODUCTION = "production";
const MTL = "mtl";
const TOR = "tor";
const TRACE = "trace";
const ERROR = "error";

const CONFIG = {
  ENVIRONMENT: (process.env.NODE_ENV || "development").trim(),
  VERSION: (process.env.VERSION || "dev").trim(),
  site: {
    name: "conduit",
    remote: "conduit-lab.camr.io",
    qa: "conduit.qa.camr.io",
    mtl: "conduit-mtl.cbcrc.ca",
    tor: "conduit-tor.cbcrc.ca",
    syndication: "syndication.cbcrc.ca",
    lab: "conduit.lab.camr.io"
  },
  api: {
    host: "127.0.0.1:8003", // defaults to local dev settings
    use_ssl: false,
    refresh_interval: 30000, // 5 min
    base: "/api/v2",
    token: "/auth/token",
    refresh: "/auth/refresh",
    verify: "/auth/verify"
  },
  social: {
    google: {
      APP_CLIENT_ID:
        "419131545053-2t6f1er6s7no7k027oiij2ocvlb8qqug.apps.googleusercontent.com"
    }
  },
  auth: {
    redirects: {
      homeUser: { name: "home.user" },
      logout: { name: "home.user" },
      login: { name: "auth.login" },
      agreement: { name: "auth.agreement" },

      // redirect users after login depending on role if role else default
      afterLoginSuccess: {
        user: { name: "user.account.team.join" },
        guest: { name: "user.account.team.join" },
        default: { name: "home.user" }
      }
    }
  },
  catalog: {
    enabled: false,
    isPreviewPaneEnabled: false,
    displayOwnerLogo: false,
    defaultSortOrder: "name_sort",
    public: false
  },
  archives: {
    enabled: true
  },
  commerce: {
    enabled: false
  },
  deliveries: {
    enabled: true
  },
  team: {
    enabled: true
  },
  flow: {
    enabled: true
  },
  downloads: {
    miptv: "/media/uploads/downloads/MIPTV2019.pdf"
  },
  agreement: {
    version: null,
    path: ""
  },
  license: {
    version: 1,
    path: ""
  },
  supportDeskUrl: "https://distribution.cbcrc.ca/support",
  contactDeskUrl: "https://distribution.cbcrc.ca/contact",
  brightcove: {
    playerId: "HyxP4cAllW",
    accountId: "4244402394001"
  },
  plugins: {
    VueProgressBar: {
      thickness: "10px",
      color: "#ed4d4a"
    },
    VueGtm: {
      id: "GTM-TSSF85B"
    },
    aws: {
      awsKey: "AKIAIWM6LN4PA57L2CBQ",
      awsSecret: "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",
      awsBucket: "addp-test"
    }
  },
  isDevelopment() {
    return (
      typeof CONFIG.ENVIRONMENT === undefined ||
      CONFIG.ENVIRONMENT === DEVELOPMENT
    );
  },
  isProduction() {
    return (
      typeof CONFIG.ENVIRONMENT !== undefined &&
      CONFIG.ENVIRONMENT === PRODUCTION
    );
  },
  API() {
    const API = CONFIG.api;
    if (CONFIG.isProduction()) {
      API.host = "current host";
      API.use_ssl = true;
    }
    if (!CONFIG.isDevelopment()) {
      API.host = CONFIG.site[this.ENVIRONMENT];
      API.use_ssl = true;
    }
    return API;
  },
  appVersion() {
    return CONFIG.VERSION;
  },
  siteProtocol() {
    if (CONFIG.API().use_ssl) {
      return "https://";
    }
    return "http://";
  },
  siteName() {
    return CONFIG.site.name;
  },
  apiHost() {
    if (CONFIG.isProduction()) {
      return "";
    } else {
      return `${CONFIG.siteProtocol()}${CONFIG.API().host}`;
    }
  },
  apiAdminUrl() {
    return `${CONFIG.apiHost()}/admin/`;
  },
  apiBase() {
    // provide to Axios BaseUrl
    return CONFIG.apiHost() + CONFIG.API().base;
  },
  apiToken() {
    // relative to BaseUrl
    return CONFIG.API().token;
  },
  apiRefresh() {
    // relative to BaseUrl
    return CONFIG.API().refresh;
  },
  apiVerify() {
    // relative to BaseUrl
    return CONFIG.API().verify;
  },
  apiRefreshInterval() {
    return CONFIG.API().refresh_interval;
  },
  websocketProtocol() {
    if (CONFIG.API().use_ssl) {
      return "wss://";
    }
    return "ws://";
  },
  websocketHost() {
    return `${CONFIG.websocketProtocol()}${CONFIG.API().host}/ws`;
  },
  logLevel() {
    // enable TRACE when not in prod
    if (!CONFIG.isProduction()) {
      return TRACE;
    }
    return ERROR;
  },
  i18n() {
    // return CONFIG.isQA() || CONFIG.isDevelopment()
    return false;
  }
};

export default CONFIG;
