import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";

function load(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `./${view}.vue`);
}

const routes = {
  path: "archives",
  component: load("Base"),
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "",
      name: "archives.list",
      component: load("List"),
      beforeEnter: ifUserInTeam,
      meta: {
        isBypassingPageTransition: true,
        layout: "dashboard"
      },

      children: [
        {
          path: ":uid([0-9a-f]{32})/detail",
          name: "archives.detail",
          component: load("Detail"),
          meta: {
            isBypassingPageTransition: true,
            layout: "dashboard"
          }
        }
      ]
    }
  ]
};

export default routes;
