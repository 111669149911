import axios from "@/utils/axios.js";

import * as constants from "./actions";

function getDefaultState() {
  return {
    detail: { view: "rc-delivery-summary", displayName: "Asset detail" },
    aggs: {},
    logs: {
      pagination: {},
      data: [],
      status: "",
      errors: "",
      emptyFeedback: "No results found"
    },
    delivery: {
      data: {},
      status: "",
      errors: "",
      view: "",
      emptyFeedback: "No results found"
    },
    dispatch: {},
    savedMeta: { data: {}, status: "", errors: "" },
    actions: { data: {}, status: "", errors: "" },
    versioning: { data: {}, status: "", errors: "" }
  };
}

function getFilterQuery(params) {
  let url = [];
  Object.keys(params).forEach((key) => {
    if (params[key] && params[key].value) {
      url.push(params[key].value);
    }
  });
  return url.join("&");
}

const getters = {
  deliveriesFacets: (state) => {
    const drv = {
      delivery_modified_on: [],
      packager_name: [],
      updated_on: [],
      status: [],
      sort_by: ["value"]
    };

    state.aggs._filter_packager_name
      ? state.aggs._filter_packager_name.packager_name.buckets.forEach(
          (item) => {
            drv.packager_name.push({
              text: item.key,
              value: `packager_name=${item.key}`,
              count: item.doc_count
            });
          }
        )
      : [];

    state.aggs._filter_status
      ? state.aggs._filter_status.status.buckets.forEach((item) => {
          drv.status.push({
            text: item.key,
            value: `status=${item.key}`,
            count: item.doc_count
          });
        })
      : [];

    state.aggs._filter_modified_on
      ? state.aggs._filter_modified_on.modified_on.buckets.forEach((item) => {
          drv.delivery_modified_on.push({
            text: item.key,
            value: `modified_on=${item.key}`,
            count: item.doc_count
          });
        })
      : [];

    return drv;
  }
};

const actions = {
  [constants.TEAM_DELIVERIES_LOG_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_DELIVERIES_LOG_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      const url = `teams/${team_uid}/deliveries/log`;
      const searchQuery = rootState.libvue.deliveries.search.value
        ? rootState.libvue.deliveries.search.value.value
        : "";
      const filters = rootState.libvue.deliveries.filters;
      const filterQuery = getFilterQuery(filters);

      axios
        .get(`${url}?${filterQuery}&${searchQuery}`)
        .then((response) => {
          commit(constants.TEAM_DELIVERIES_LOG_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_DELIVERIES_LOG_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.DELIVERIES_LOG_RESTART]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.DELIVERIES_LOG_RESTART);
      const team_uid = rootState.auth.user.team_uid;
      const deliverylog_uid = rootState.deliveries.delivery.data.uid;
      const url = `teams/${team_uid}/deliveries/log/${deliverylog_uid}/restart-delivery`;

      axios
        .post(url, { ...params })
        .then((response) => {
          commit(constants.DELIVERIES_LOG_RESTART_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELIVERIES_LOG_RESTART_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.DELIVERY_SEARCH_SET_ORDER_BY]: ({ commit, dispatch }, orderBy) =>
    new Promise((resolve, reject) => {
      commit(constants.DELIVERY_SEARCH_SET_ORDER_BY, orderBy);
      dispatch(constants.TEAM_DELIVERIES_LOG_REQUEST)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    }),
  [constants.TEAM_DELIVERIES_APPEND_NEXT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_DELIVERIES_APPEND_NEXT);

      const url = params.next ? params.next : "";

      axios
        .get(url)
        .then((response) => {
          commit(constants.TEAM_DELIVERIES_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_DELIVERIES_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.DELIVERIES_SAVE_META]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      const team_uid = rootState.auth.user.team_uid;
      const deliverylog_uid = params.deliverylog_uid;
      commit(constants.DELIVERIES_SAVE_META);
      axios
        .post(
          `teams/${team_uid}/deliveries/log/${deliverylog_uid}/save-metadata`,
          { xml_content: params.xml_content }
        )
        .then((response) => {
          commit(constants.DELIVERIES_SAVE_META_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELIVERIES_SAVE_META_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.DELIVERY_GET_ACTIONS]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      const team_uid = rootState.auth.user.team_uid;
      const deliverylog_uid = rootState.deliveries.delivery.data.uid;
      commit(constants.DELIVERY_GET_ACTIONS);


      if (!deliverylog_uid) {
        reject("No delivery log UID found");
      } else {
        axios
          .get(
            `teams/${team_uid}/deliveries/log/${deliverylog_uid}/restart-delivery-availability`
          )
          .then((response) => {
            commit(constants.DELIVERY_GET_ACTIONS_SUCCESS, response);
            resolve(response);
          })
          .catch((error) => {
            commit(constants.DELIVERY_GET_ACTIONS_ERROR, error);
            reject(error);
          });
      }

    });
  },
  [constants.DELIVERY_ALLOW_VERSION]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      const team_uid = rootState.auth.user.team_uid;
      const deliverylog_uid = rootState.deliveries.delivery.data.uid;
      commit(constants.DELIVERY_ALLOW_VERSION);
      axios
        .patch(
          `teams/${team_uid}/deliveries/log/${deliverylog_uid}/allow-versioning`,
          {
            allow_versioning: true
          }
        )
        .then((response) => {
          commit(constants.DELIVERY_ALLOW_VERSION_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELIVERY_ALLOW_VERSION_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.TEAM_DELIVERIES_LOG_REQUEST]: (state) => {
    state.logs.status = "fetching";
    state.logs.errors = {};
    state.logs.data = [];
  },
  [constants.TEAM_DELIVERIES_LOG_REQUEST_SUCCESS]: (state, { data }) => {
    state.aggs = data.aggregations;
    state.logs.status = "success";
    state.logs.errors = {};
    state.logs.data = data.results;
    state.logs.pagination = {
      count: data.count,
      next: data.next,
      page_size: data.page_size
    };
  },
  [constants.TEAM_DELIVERIES_LOG_REQUEST_ERROR]: (state, err) => {
    state.logs.status = "error";
    state.logs.errors = err.response ? err.response.data : err;
  },
  [constants.DELIVERIES_SET_SELECTED]: (state, delivery) => {
    state.status = `success`;
    state.errors = {};
    state.delivery.data = delivery;
  },
  [constants.DELIVERIES_SET_VIEW_COMPONENT]: (state, detail) => {
    state.status = `success`;
    state.errors = {};
    state.detail = detail;
  },
  [constants.TEAM_DELIVERIES_APPEND_NEXT]: (state) => {
    state.logs.flowLogStatus = `fetching`;
    state.errors = {};
  },
  [constants.TEAM_DELIVERIES_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.aggs = data.aggregations;
    state.logs.status = `success`;
    state.logs.errors = {};
    state.logs.data = [...state.logs.data, ...data.results];
    state.logs.pagination = {
      count: data.count,
      next: data.next,
      page_size: data.page_size
    };
  },
  [constants.TEAM_DELIVERIES_APPEND_NEXT_ERROR]: (state, err) => {
    state.logs.status = `error`;
    state.logs.errors = err.response ? err.response.data : err;
  },
  [constants.DELIVERIES_LOG_RESTART]: (state) => {
    state.delivery.status = "fetching";
    state.delivery.errors = {};
  },
  [constants.DELIVERIES_LOG_RESTART_SUCCESS]: (state) => {
    state.delivery.status = "success";
    state.delivery.rrors = {};
  },
  [constants.DELIVERIES_LOG_RESTART_ERROR]: (state, err) => {
    state.delivery.deliverystatus = "error";
    state.delivery.errors = err;
    err.response ? err.response.data : err;
  },
  [constants.DELIVERY_SEARCH_SET_ORDER_BY]: (state, orderBy) => {
    state.orderBy = orderBy;
  },
  [constants.DELIVERIES_SET_SELECTED_DISPATCH]: (state, dispatch) => {
    state.dispatch = dispatch;
  },
  [constants.DELIVERIES_SAVE_META]: (state) => {
    state.savedMeta.status = "fetching";
    state.savedMeta.errors = {};
  },
  [constants.DELIVERIES_SAVE_META_SUCCESS]: (state, { data }) => {
    state.savedMeta.status = "success";
    state.savedMeta.errors = {};
    state.savedMeta.data = data;
  },
  [constants.DELIVERIES_SAVE_META_ERROR]: (state, err) => {
    state.savedMeta.status = "error";
    state.savedMeta.errors = err;
  },
  [constants.DELIVERY_GET_ACTIONS]: (state) => {
    state.actions.status = "fetching";
    state.actions.errors = {};
    state.actions = { data: {}, status: "", errors: "" };
  },
  [constants.DELIVERY_GET_ACTIONS_SUCCESS]: (state, { data }) => {
    state.actions.status = "success";
    state.actions.errors = {};
    state.actions.data = data;
  },
  [constants.DELIVERY_GET_ACTIONS_ERROR]: (state, err) => {
    state.actions.status = "error";
    state.actions.errors = err;
  },
  [constants.DELIVERY_ALLOW_VERSION]: (state) => {
    state.versioning.status = "fetching";
    state.versioning.errors = {};
  },
  [constants.DELIVERY_ALLOW_VERSION_SUCCESS]: (state, { data }) => {
    state.versioning.status = "success";
    state.versioning.errors = {};
    state.versioning.data = data;
  },
  [constants.DELIVERY_ALLOW_VERSION_ERROR]: (state, err) => {
    state.versioning.status = "error";
    state.versioning.errors = err;
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
