import Vue from "vue";
import App from "#/App.vue";
import Config from "#/config";
import i18n from "#/locales";

import "regenerator-runtime/runtime.js";

import routes from "#/routes";
import store from "#/stores";
import { ROOT_UPDATE_IF_TOUCH_DEVICE } from "#/stores/root";
import { registerComponent } from "@/libvuePure/bootstrap";

import "./app.scss";

// Global registration
registerComponent(Vue);

// Site imports
import "#/plugins";
// Site Registration
import "#/components";
import "#/layout";
// Global imports
import "@/plugins";
import "@/global/directives.js";

// Pass store functions from plugins
store.$connect = Vue.prototype.$connect; // socket connect instance in store
store.$disconnect = Vue.prototype.$disconnect; // socket disconnect instance in store
store.$router = routes; // socket disconnect instance in store

/* eslint-disable no-console */
console.log(
  `Running version ${Config.appVersion()} ['${
    Config.ENVIRONMENT
  }'] using API: ${Config.API().api_host_name}`
);

/* eslint-disable no-new */
export default new Vue({
  el: "#app",
  router: routes,
  store,
  i18n,
  components: { App },
  template: "<App/>",
  methods: {
    updateBodyClasses() {
      const isUserOnATouchDevice = navigator.userAgent.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i
      );
      document
        .querySelector("body")
        .classList.add(isUserOnATouchDevice ? "touch" : "no-touch");
      store.dispatch(ROOT_UPDATE_IF_TOUCH_DEVICE, isUserOnATouchDevice);
    }
  },
  created: function () {
    this.updateBodyClasses();
  }
});
