import * as constants from "./actions";

function getDefaultState() {
  return {
    id: null,
    open: false
  };
}

const actions = {
  [constants.MODAL_OPEN]: ({ commit }, data) => {
    commit(constants.MODAL_OPEN, data);
  },
  [constants.MODAL_CLOSE]: ({ commit }, data) => {
    commit(constants.MODAL_CLOSE, data);
  }
};

const mutations = {
  [constants.MODAL_OPEN]: (state, { id }) => {
    state.open = true;
    state.id = id;
  },
  [constants.MODAL_CLOSE]: (state, { id }) => {
    if (id === state.id) {
      state.open = false;
      state.id = null;
    }
  },
  [constants.MODAL_RESET]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
