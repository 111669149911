import Vue from "vue";

// user modals
import AddMemberModal from "@/libvue/apps/user/account/AddMemberModal.vue";
import EditProfileModal from "@/libvue/apps/user/account/EditProfileModal.vue";
Vue.component("rc-add-member-modal", AddMemberModal);
Vue.component("rc-edit-profile-modal", EditProfileModal);

// team modals
import CreateNewTeamModal from "@/libvue/apps/team/components/createNewTeamModal.vue";
Vue.component("rc-create-new-team-modal", CreateNewTeamModal);
