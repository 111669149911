<template>
  <div id="app">
    <RcPageLoader>
      <component :is="layout"></component>
    </RcPageLoader>
    <vue-progress-bar></vue-progress-bar>
    <RcUploaderModal />
  </div>
</template>

<script>
import RcPageLoader from "@/libvue/components/pageLoader/PageLoader.vue";
import RcUploaderModal from "@/libvue/apps/upload/components/UploaderModal.vue";

const defaultLayout = "default";

export default {
  name: "App",
  components: {
    RcUploaderModal,
    RcPageLoader
  },

  data() {
    return {
      mobileMenuBackdropListener: null
    };
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    }
  },
  mounted() {
    this.$Progress.finish();
  },

  created() {
    this.$Progress.start();

    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }

      this.$Progress.start();

      next();
    });

    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  }
};
</script>
