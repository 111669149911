import moment from "moment-timezone";
import i18n from "#/locales";

export function usePrettyBytes(bytes) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof bytes !== "number" || isNaN(bytes)) {
    throw new TypeError("Expected a number");
  }

  var exponent;
  var unit;
  var neg = bytes < 0;
  var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (neg) {
    bytes = -bytes;
  }

  if (bytes < 1) {
    return (neg ? "-" : "") + bytes + " B";
  }

  exponent = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1000)),
    units.length - 1
  );
  bytes = (bytes / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? "-" : "") + bytes + " " + unit;
}

export function useDateFormats(dateStr, preset = "MMM. DD, YYYY") {
  moment.locale(i18n.locale);
  return dateStr
    ? moment.tz(dateStr, "Etc/UTC").tz(moment.tz.guess()).format(preset)
    : "";
}

export function useTimeRelative(dateStr) {
  let date = null;

  if (moment.locale() != "fr" && i18n.locale == "fr") {
    moment.updateLocale("fr", {
      relativeTime: {
        future: "dans %s",
        past: "il y a %s",
        s: "quelques secondes",
        ss: "%d secondes",
        m: "une minute",
        mm: "%d minutes",
        h: "une heure",
        hh: "%d heures",
        d: "un jour",
        dd: "%d jours",
        w: "une semaine",
        ww: "%d semaines",
        M: "un mois",
        MM: "%d mois",
        y: "un ans",
        yy: "%d ans"
      }
    });
  } else if (moment.locale() != "en" && i18n.locale == "en") {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%d seconds",
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        w: "a week",
        ww: "%d weeks",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
      }
    });
  }

  date = moment.tz(dateStr, "Etc/UTC").tz(moment.tz.guess());

  if (date._isValid) {
    const dateDiff = moment().diff(date, "days");

    if (dateDiff == 1) {
      date = date.calendar();
    }
    if (dateDiff > 1) {
      date = date.format("lll");
    } else {
      date = date.fromNow();
    }

    return date;
  }

  return dateStr;
}
