<template>
  <div class="rc-wrapper rc-playlist-detail-layout">
    <rc-skip-nav />
    <rc-site-header />
    <div class="main-container">
      <rc-site-content />
    </div>
    <rc-notifications />
  </div>
</template>

<script>
import SkipNav from "@/libvue/components/skipnav/SkipNav.vue";
import SiteHeader from "#/layout/components/SiteHeader.vue";
import SiteContent from "#/layout/components/SiteContent.vue";

export default {
  name: "DetailPlaylistLayout",
  components: {
    "rc-skip-nav": SkipNav,
    "rc-site-header": SiteHeader,
    "rc-site-content": SiteContent
  }
};
</script>
