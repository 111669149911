// Site plugins
import Vue from "vue";
import VueGtm from "vue-gtm";
import Multiselect from "vue-multiselect";
import Config from "#/config";
import routes from "#/routes";
import Vuelidate from "vuelidate";
import Evaporate from "evaporate";
import sparkMD5 from "spark-md5";

import "vue-multiselect/dist/vue-multiselect.min.css";

const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuelidate);
Vue.component("RcMultiselect", Multiselect);

Object.defineProperty(Vue.prototype, "$evaporate", { value: Evaporate });
Object.defineProperty(Vue.prototype, "$sparkMD5", { value: sparkMD5 });

// production plugins
if (Config.ENVIRONMENT == "production") {
  Vue.use(VueGtm, {
    id: Config.plugins.VueGtm.id,
    debug,
    vueRouter: routes
  });
}
