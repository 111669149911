<template>
  <b-modal :id="modalId" :title="$t('team.add_member')" title-tag="h2">
    <template #modal-footer="{ cancel }">
      <rc-btn variant="secondary" @click="cancel()">Cancel</rc-btn>
      <rc-btn size="lg" variant="primary" @click="handleOk()">{{
        $t("team.add")
      }}</rc-btn>
    </template>
    <form class="form">
      <RcForm :form="form"></RcForm>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { TEAM_MEMBER_INVITE } from "@/libvue/apps/team/actions";
import RcForm from "@/libvue/components/form/Form.vue";
import { formValidationMixin } from "@/libvue/components/form/mixins";

export default {
  name: "AddMemberModal",

  components: {
    RcForm
  },

  mixins: [formValidationMixin],

  data() {
    return {
      errors: {},
      modalId: "addMemberModal",
      formState: true
    };
  },
  computed: {
    form() {
      return {
        fields: [
          {
            name: "email",
            value: "",
            required: true,
            disabled: false,
            type: "email",
            label: this.$t("common.email"),
            validate: {
              active: true,
              isValid: null,
              message: "Please provide an email",
              blured: false,
              maxLength: 200
            }
          }
        ]
      };
    }
  },
  methods: {
    handleOk() {
      if (this.validateAll()) {
        const localData = {
          ...this.formatPayload(),
          is_admin: false
        };
        this.invite(localData)
          .then((res) => {
            this.$notify.success(
              `${res.data.user.email} will receive membership email shortly`
            );
            this.$bvModal.hide(this.modalId);
          })
          .catch((error) => {
            this.form.fields.forEach((f) => {
              if (f.name == "email") {
                f.validate.isValid = false;
                f.validate.message = error.response.data.detail;
              }
            });
          });
      }
    },
    ...mapActions({
      invite: TEAM_MEMBER_INVITE
    })
  }
};
</script>
