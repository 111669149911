<template>
  <div class="rc-wrapper rc-util-layout">
    <rc-skip-nav/>
    <rc-auth-status-banner />
    <rc-site-header
      :class="{
        disabled:
          this.$route.meta.layoutOptions &&
          this.$route.meta.layoutOptions.disableHeader
      }"
    />
    <div class="main-container">
      <rc-site-content />
    </div>
    <rc-site-footer
      v-if="
        !this.$route.meta.layoutOptions ||
          !this.$route.meta.layoutOptions.hideFooter
      "
    />
    <rc-notifications />
  </div>
</template>

<script>
import SkipNav from "@/libvue/components/skipnav/SkipNav.vue";
import SiteContent from "#/layout/components/SiteContent.vue";
import SiteFooter from "#/layout/components/SiteFooter.vue";
import SiteHeader from "#/layout/components/SiteHeader.vue";
import AuthStatusBanner from "@/libvue/apps/auth/components/StatusBanner.vue";


export default {
  name: "UtilLayout",
  components: {
    "rc-skip-nav": SkipNav,
    "rc-site-content": SiteContent,
    "rc-site-footer": SiteFooter,
    "rc-site-header": SiteHeader,
    "rc-auth-status-banner": AuthStatusBanner
  }
};
</script>
