var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-footer"},[_c('rc-btn',{staticClass:"btn-block",attrs:{"to":{ name: 'flow.list', params: { locale: _vm.$i18n.locale } },"variant":"primary"}},[_vm._v("Monitor flows")])],1)])]),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-footer"},[_c('rc-btn',{staticClass:"btn-block",attrs:{"to":{ name: 'deliveries.list' },"variant":"primary"}},[_vm._v("Monitor deliveries")])],1)])]),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{staticClass:"card-footer"},[_c('rc-btn',{staticClass:"btn-block",attrs:{"to":{
                name: 'archives.list',
                params: { locale: _vm.$i18n.locale }
              },"variant":"primary"}},[_vm._v("Search archives")])],1)])]),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card"},[_vm._m(4),_c('div',{staticClass:"card-footer"},[_c('rc-btn',{staticClass:"btn-block",attrs:{"to":{ name: 'upload.list', params: { locale: _vm.$i18n.locale } },"variant":"primary"}},[_vm._v("Upload files")])],1)])])])]),_c('div',{staticClass:"container"},[_c('RcPrismTextEditor')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"rc-page-header"},[_c('h1',[_vm._v("Conduit")]),_c('div',{staticClass:"rc-intro"},[_c('p',[_vm._v(" Your one-stop service for process tracking, file delivery and other cloud-based wizardry. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h2',[_vm._v("Flow")]),_c('p',[_vm._v(" Find your files as they go through the mind boggling world of automatic processes. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h2',[_vm._v("Delivery")]),_c('p',[_vm._v(" Tracking ongoing deliveries has just become easier then ever. Get ready for high-paced status updates. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h2',[_vm._v("Archive")]),_c('p',[_vm._v(" Browse the evergrowing list of archived files with our top of the line document seeker tool. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h2',[_vm._v("Upload")]),_c('p',[_vm._v(" Ingest your very own local medias to Conduit flows. Sit back relax and let the magic begin. ")])])
}]

export { render, staticRenderFns }