import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";


function load(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/libvue/apps/upload/${view}.vue`
    );
}


const routes = {
  path: "upload",
  component: load("Base"),
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "",
      name: "upload.list",
      component: load("List"),
      beforeEnter: ifUserInTeam,
      meta: {
        isBypassingPageTransition: true,
        layout: "dashboard"
      },

      children: [
        {
          path: ":uid*/detail",
          name: "upload.detail",
          component: load("Detail"),
          meta: {
            isBypassingPageTransition: true,
            layout: "dashboard"
          }
        }
      ]
    }
  ]
};

export default routes;
