<template>
    <div class="rc-wrapper">
        <rc-skip-nav/>
        <rc-site-header/>
        <rc-site-content/>
        <rc-site-footer/>
        <rc-notifications/>
    </div>
</template>

<script>
import SkipNav from "@/libvue/components/skipnav/SkipNav.vue";
import SiteContent from "#/layout/components/SiteContent.vue";
import SiteFooter from "#/layout/components/SiteFooter.vue";
import SiteHeader from "#/layout/components/SiteHeader.vue";

export default {
    name: "RegisterLayout",
    components: {
        "rc-skip-nav": SkipNav,
        "rc-site-content": SiteContent,
        "rc-site-footer": SiteFooter,
        "rc-site-header": SiteHeader
    }
};
</script>
