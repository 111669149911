import axios from "@/utils/axios.js";
import * as constants from "./actions";
import { LIB_DRAWER_SET_STATE } from "@/libvue/components/actions";

function getDefaultState() {
  return {
    flows: {
      data: { results: [] },
      status: "",
      errors: {},
      emptyFeedback: "no flow corresponding to this query"
    },
    flow: {
      data: {},
      status: "",
      errors: {},
      emptyFeedback: "no flow corresponding to this query"
    },
    logDetail: {
      data: {},
      errors: {},
      status: "",
      emptyFeedback: "no log corresponding to this uid"
    },
    logList: {
      data: { results: [], aggregations: {}, count: 0 },
      status: "",
      errors: {},
      emptyFeedback: "No results found"
    },
    regulatorFacets: {
      data: [],
      status: "",
      errors: {},
      emptyFeedback: "no regulator corresponding to this query"
    },
    batchregulators: [],
    detail: { view: "rc-flow-summary", displayName: "LOG DETAIL" },

    autocomplete: {
      queryInInput: "",
      data: [],
      status: "",
      errors: ""
    },
    valve: {
      data: {},
      status: "",
      errors: ""
    },
    valves: {
      data: { results: [] },
      status: "",
      errors: "",
      emptyFeedback: "No process found"
    },
    siblings: {
      data: { results: [] },
      status: "",
      errors: "",
      emptyFeedback: "No log history found",
      parent: ""
    },
    sibling: {
      data: {},
      status: "",
      errors: "",
      emptyFeedback: "There is no sibling corresponding to this tracking number"
    },
    pipeline: {
      data: {},
      status: "",
      errors: {}
    },
    renamed: {
      data: {},
      status: "",
      errors: {}
    }
  };
}

function getFacetCount(item, aggregations, context) {
  let result = 0;
  const aggs =
    aggregations && context in aggregations
      ? aggregations[context].buckets
      : [];

  aggs.forEach((agg) => {
    if (agg.key == item.uid) {
      result = agg.doc_count;
    }
  });
  return result;
}

function getFilterQuery(params) {
  let url = [];
  Object.keys(params).forEach((key) => {
    if (params[key] && params[key].value) {
      url.push(params[key].value);
    }
  });
  return url.join("&");
}

const createParamUrl = (baseUrl, values) => {
  return values.filter((u) => u).length
    ? values
        .filter((u) => u)
        .join("&")
        .replace(/^/, "?")
        .replace(/^/, baseUrl)
    : values
        .filter((u) => u)
        .join("&")
        .replace(/^/, baseUrl);
};

const getters = {
  getDistinctValues: (state) => {
    const drv = {
      regulator_names: [],
      flow_names: [],
      regulator_status: [],
      updated_on: []
    };

    state.logList.data.aggregations._filter_status
      ? state.logList.data.aggregations._filter_status.status.buckets.forEach(
          (item) => {
            drv.regulator_status.push({
              text: item.key,
              value: `status=${item.key}`,
              count: item.doc_count
            });
          }
        )
      : [];

    state.regulatorFacets.data
      ? state.regulatorFacets.data.forEach((item) => {
          drv.regulator_names.push({
            text: item.name,
            value: `regulator_id=${item.uid}`,
            count: getFacetCount(
              item,
              state.logList.data.aggregations._filter_regulator_name,
              "regulator_name"
            )
          });
        })
      : [];

    state.flows
      ? state.flows.data.results.forEach((item) => {
          drv.flow_names.push({
            text: `${item.name}`,
            value: `flow_id=${item.uid}`,
            count: getFacetCount(
              item,
              state.logList.data.aggregations._filter_flow_name,
              "flow_name"
            )
          });
        })
      : [];

    state.logList.data.aggregations._filter_updated_on
      ? state.logList.data.aggregations._filter_updated_on.updated_on.buckets.forEach(
          (item) => {
            drv.updated_on.push({
              text: item.key,
              value: `updated_on=${item.key}`,
              count: item.doc_count
            });
          }
        )
      : [];

    return drv;
  }
};

const actions = {
  [constants.TEAM_FLOWS_LIST_REQUEST]: ({ commit, rootState }, params = {}) => {
    return new Promise((resolve, reject) => {
      commit(constants.TEAM_FLOWS_LIST_REQUEST);
      const team_uid = rootState.auth.user.team_uid;

      axios
        .get(`teams/${team_uid}/flows`, { params })
        .then((response) => {
          commit(constants.TEAM_FLOWS_LIST_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.TEAM_FLOWS_LIST_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.FLOWS_SEARCH_SET_ORDER_BY]: ({ commit, dispatch }, orderBy) =>
    new Promise((resolve, reject) => {
      commit(constants.FLOWS_SEARCH_SET_ORDER_BY, orderBy);
      dispatch(constants.FLOWS_LOG_LIST_REQUEST)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    }),

  [constants.FLOWS_LOG_LIST_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOWS_LOG_LIST_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      const url = `teams/${team_uid}/flows/log`;
      const searchQuery = rootState.libvue.flow.search.value
        ? rootState.libvue.flow.search.value.value
        : "";
      const filters = rootState.libvue.flow.filters;
      const filterQuery = getFilterQuery(filters);

      axios
        .get(createParamUrl(url, [searchQuery, filterQuery]))
        .then((response) => {
          commit(constants.FLOWS_LOG_LIST_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOWS_LOG_LIST_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.FLOWS_LOG_AGGS_REQUEST]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOWS_LOG_AGGS_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      const url = `teams/${team_uid}/flows/log`;
      const searchQuery = rootState.libvue.flow.search.value
        ? rootState.libvue.flow.search.value.value
        : "";
      const filters = rootState.libvue.flow.filters;
      const filterQuery = getFilterQuery(filters);

      var re = new RegExp(params, "g");
      const aggsFilter = filterQuery.replace(re, "");

      axios
        .get(`${url}?limit=0&${aggsFilter}&${searchQuery}`)
        .then((response) => {
          commit(constants.FLOWS_LOG_AGGS_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOWS_LOG_AGGS_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.FLOWS_LOG_GET_REQUEST]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOWS_LOG_GET_REQUEST, params);
      const team_uid = rootState.auth.user.team_uid;
      const flow_uid = params.flowUid;
      const url = `teams/${team_uid}/flows/log/${flow_uid}`;

      axios
        .get(url)
        .then((response) => {
          commit(constants.FLOWS_LOG_GET_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOWS_LOG_GET_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.FLOWS_LOG_SIBLINGS_REQUEST]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOWS_LOG_SIBLINGS_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      const tracking = `tracking_number=${params.tracking_number}`;
      const to_ingested_on = `to_updated_on=${params.updated_on}`;
      const url = `teams/${team_uid}/flows/log`;

      axios
        .get(`${url}?${tracking}&${to_ingested_on}`)
        .then((response) => {
          commit(constants.FLOWS_LOG_SIBLINGS_REQUEST_SUCCESS, {
            response,
            params
          });

          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOWS_LOG_SIBLINGS_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.FLOW_LOG_AUTOCOMPLETE_REQUEST]: (
    { commit, rootState },
    params
  ) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOW_LOG_AUTOCOMPLETE_REQUEST);

      const team_uid = rootState.auth.user.team_uid;
      const url = `teams/${team_uid}/flows/log`;
      const query = params && params.q ? `${params.q}` : "";
      const suggest =
        params && params.suggest ? `suggest=${params.suggest}` : "";
      const filterQuery = getFilterQuery(params) ? getFilterQuery(params) : "";
      axios
        .get(`${url}?${query}&${filterQuery}&${suggest}`)
        .then((response) => {
          commit(constants.FLOW_LOG_AUTOCOMPLETE_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOW_LOG_AUTOCOMPLETE_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.FLOWS_LOG_APPEND_NEXT_REQUEST]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOWS_LOG_APPEND_NEXT_REQUEST);

      const url = params.next ? params.next : "";

      axios
        .get(url)
        .then((response) => {
          commit(constants.FLOWS_LOG_APPEND_NEXT_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOWS_LOG_APPEND_NEXT_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.REGULATORS_LIST_REQUEST]: ({ commit, rootState }, params = {}) => {
    return new Promise((resolve, reject) => {
      commit(constants.REGULATORS_LIST_REQUEST);
      const team_uid = rootState.auth.user.team_uid;

      axios
        .get(`/teams/${team_uid}/regulators`, {
          params: { ...params, page_size: 50 }
        })
        .then((response) => {
          commit(constants.REGULATORS_LIST_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.REGULATORS_LIST_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.REGULATOR_REQUEST]: ({ commit, rootState }, params = {}) => {
    return new Promise((resolve, reject) => {
      commit(constants.REGULATOR_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      const regulator_uid = params.uid;

      axios
        .get(`/teams/${team_uid}/regulators/${regulator_uid}`)
        .then((response) => {
          commit(constants.REGULATOR_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.REGULATOR_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.VALVES_LOG_LIST_REQUEST]: (
    { commit, rootState },
    regulator_pipeline_result_id
  ) => {
    return new Promise((resolve, reject) => {
      commit(constants.VALVES_LOG_LIST_REQUEST);

      const team_uid = rootState.auth.user.team_uid;
      const query = regulator_pipeline_result_id
        ? `?regulator_pipeline_result_id=${regulator_pipeline_result_id}`
        : "";

      axios
        .get(`/teams/${team_uid}/valves/log${query}`)
        .then((response) => {
          commit(constants.VALVES_LOG_LIST_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.VALVES_LOG_LIST_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.FLOW_VALVES_GET_REQUEST]: ({ commit, rootState }, params = {}) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOW_VALVES_GET_REQUEST);
      const team_uid = rootState.auth.user.team_uid;
      const valve_uid = params.uid;

      axios
        .get(`/teams/${team_uid}/valves/${valve_uid}`)
        .then((response) => {
          commit(constants.FLOW_VALVES_GET_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOW_VALVES_GET_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.FLOW_VALVES_SET_SELECTED]: ({ commit }, valve) => {
    return commit(constants.FLOW_VALVES_SET_SELECTED, valve);
  },
  [constants.FLOWS_LOG_RESET_ASSET]: ({ commit, rootState, state }) => {
    return new Promise((resolve, reject) => {
      commit(constants.FLOWS_LOG_RESET_ASSET);

      const team_uid = rootState.auth.user.team_uid;
      const flowlog_uid = state.logDetail.data.uid;

      axios
        .post(`teams/${team_uid}/flows/log/${flowlog_uid}/reset-asset`)
        .then((response) => {
          commit(constants.FLOWS_LOG_RESET_ASSET_SUCCESS, flowlog_uid);
          commit(LIB_DRAWER_SET_STATE, {
            visible: false
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOWS_LOG_RESET_ASSET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.VALVES_LOG_RESET]: ({ commit, rootState, state, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(constants.VALVES_LOG_RESET);

      const team_uid = rootState.auth.user.team_uid;
      const valvelog_uid = state.valve.data.uid;

      axios
        .post(`teams/${team_uid}/valves/log/${valvelog_uid}/reset-asset`)
        .then((response) => {
          commit(constants.VALVES_LOG_RESET_SUCCESS, response);
          commit(constants.FLOW_REGULATORS_SET_SELECTED, {});
          commit(LIB_DRAWER_SET_STATE, {
            visible: false
          });

          setTimeout(() => {
            dispatch(constants.FLOWS_LOG_LIST_REQUEST);
          }, 750);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.VALVES_LOG_RESET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.PIPELINE_REQUEST]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.PIPELINE_REQUEST);
      axios
        .get(`/pipelines/${params.uid}`)
        .then((response) => {
          commit(constants.PIPELINE_REQUEST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PIPELINE_REQUEST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.FLOW_LOG_RENAME_ASSET]: (
    { commit, rootState, state },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      const team_uid = rootState.auth.user.team_uid;
      const flowlog_uid = state.logDetail.data.uid;
      commit(constants.FLOW_LOG_RENAME_ASSET);
      axios
        .post(
          `teams/${team_uid}/flows/log/${flowlog_uid}/rename-asset`,
          payload
        )

        .then((response) => {
          commit(constants.FLOW_LOG_RENAME_ASSET_SUCCESS, {
            payload: { flowlog_uid }
          });
          commit(constants.FLOW_REGULATORS_SET_SELECTED, {});
          commit(LIB_DRAWER_SET_STATE, {
            visible: false
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FLOW_LOG_RENAME_ASSET_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.TEAM_FLOWS_LIST_REQUEST]: (state) => {
    state.flows.status = `fetching`;
    state.flows.errors = {};
  },
  [constants.TEAM_FLOWS_LIST_REQUEST_SUCCESS]: (state, { data }) => {
    state.flows.status = `success`;
    state.flows.errors = {};
    state.flows.data = data;
  },
  [constants.TEAM_FLOWS_LIST_REQUEST_ERROR]: (state, err) => {
    state.flows.status = `error`;
    state.flows.errors = err.response ? err.response.data : err;
  },
  [constants.FLOWS_SEARCH_SET_ORDER_BY]: (state, orderBy) => {
    state.orderBy = orderBy;
  },
  [constants.FLOWS_LOG_LIST_REQUEST]: (state) => {
    state.logList.status = `fetching`;
    state.logList.errors = {};
    state.logList.data = getDefaultState().logList.data;
  },
  [constants.FLOWS_LOG_LIST_REQUEST_SUCCESS]: (state, { data }) => {
    state.logList.status = `success`;
    state.logList.errors = {};
    state.logList.data = data;
  },
  [constants.FLOWS_LOG_LIST_REQUEST_ERROR]: (state, err) => {
    state.logList.status = `error`;
    state.logList.errors = err.response ? err.response.data : err;
  },
  [constants.FLOWS_LOG_AGGS_REQUEST]: () => {
    // state.logList.status = `fetching`;
  },
  [constants.FLOWS_LOG_AGGS_REQUEST_SUCCESS]: (state, { data }) => {
    state.logList.data.aggregations = data.aggregations;
  },
  [constants.FLOWS_LOG_AGGS_REQUEST_ERROR]: (state, err) => {
    state.logList.status = `error`;
    state.logList.errors = err.response ? err.response.data : err;
  },
  [constants.FLOWS_LOG_GET_REQUEST]: (state, params) => {
    state.logDetail.status = `fetching`;
    state.logDetail.erros = {};
    state.logDetail.data = { uid: params.flowUid, asset: {} };
  },
  [constants.FLOWS_LOG_GET_REQUEST_SUCCESS]: (state, { data }) => {
    state.logDetail.status = `success`;
    state.logDetail.erros = {};
    state.logDetail.data = data;

    const foundIndex = state.logList.data.results.findIndex(
      (x) => x.uid == data.uid
    );
    state.logList.data.results.splice(foundIndex, 1, data);
  },
  [constants.FLOWS_LOG_GET_REQUEST_ERROR]: (state, err) => {
    state.logDetail.status = `error`;
    state.logDetail.status = err.response ? err.response.data : err;
  },
  [constants.FLOWS_LOG_SIBLINGS_REQUEST]: (state) => {
    state.siblings.status = `fetching`;
    state.siblings.errors = {};
    state.siblings.data = { results: [] };
  },
  [constants.FLOWS_LOG_SIBLINGS_REQUEST_SUCCESS]: (
    state,
    { response, params }
  ) => {
    state.siblings.status = `success`;
    state.siblings.errors = {};
    state.siblings.data.results = response.data.results.filter(
      (s) => s.uid != params.uid
    );
    state.siblings.parent = params.uid;
  },
  [constants.FLOWS_LOG_SIBLINGS_REQUEST_ERROR]: (state, err) => {
    state.siblings.status = `error`;
    state.siblings.errors = err.response ? err.response.data : err;
  },
  [constants.FLOW_LOG_AUTOCOMPLETE_REQUEST]: (state) => {
    state.autocomplete.status = "fetching";
    state.autocomplete.errors = {};
    state.autocomplete.data = [];
  },
  [constants.FLOW_LOG_AUTOCOMPLETE_REQUEST_SUCCESS]: (state, { data }) => {
    state.autocomplete.status = "success";
    state.autocomplete.errors = {};
    state.autocomplete.data = data.suggest.source_name_suggest[0].options;
  },
  [constants.FLOW_LOG_AUTOCOMPLETE_REQUEST_ERROR]: (state, err) => {
    state.autocomplete.status = "error";
    state.autocomplete.errors = err.response ? err.response.data : err;
  },
  [constants.FLOWS_LOG_APPEND_NEXT_REQUEST]: (state) => {
    state.logList.status = `fetching`;
    state.logList.errors = {};
  },
  [constants.FLOWS_LOG_APPEND_NEXT_REQUEST_SUCCESS]: (state, { data }) => {
    state.logList.status = `success`;
    state.logList.errors = {};
    state.logList.data.results = [
      ...state.logList.data.results,
      ...data.results
    ];
    delete data.results;
    state.logList.data = { ...state.logList.data, ...data };
  },
  [constants.FLOWS_LOG_APPEND_NEXT_REQUEST_ERROR]: (state, err) => {
    state.logList.status = `error`;
    state.logList.errors = err.response ? err.response.data : err;
  },
  [constants.REGULATORS_LIST_REQUEST]: (state) => {
    state.regulatorFacets.status = `regulator GET request`;
    state.regulatorFacets.errors = {};
    state.regulatorFacets.data = [];
  },
  [constants.REGULATORS_LIST_REQUEST_SUCCESS]: (state, { data }) => {
    state.regulatorFacets.status = `success`;
    state.regulatorFacets.errors = {};
    state.regulatorFacets.data = data.results;
  },
  [constants.REGULATORS_LIST_REQUEST_ERROR]: (state, err) => {
    state.regulatorFacets.status = `error`;
    state.regulatorFacets.errors = err.response ? err.response.data : err;
  },
  [constants.REGULATOR_REQUEST]: (state) => {
    state.logDetail.status = "fetching";
    state.logDetail.errors = {};
  },
  [constants.REGULATOR_REQUEST_SUCCESS]: (state, { data }) => {
    state.logDetail.status = "success";
    state.logDetail.errors = {};
    state.logDetail.data = data;
  },
  [constants.REGULATOR_REQUEST_ERROR]: (state, err) => {
    state.logDetail.status = "error";
    state.logDetail.errors = err;
  },
  [constants.FLOW_REGULATORS_SET_SELECTED]: (state, regulator) => {
    state.logDetail.status = `success`;
    state.logDetail.errors = {};
    state.logDetail.data = regulator;
  },
  [constants.VALVES_LOG_LIST_REQUEST]: (state) => {
    state.valves.status = `fetching`;
    state.valves.errors = {};
    state.valves.data = getDefaultState().valves.data;
  },
  [constants.VALVES_LOG_LIST_REQUEST_SUCCESS]: (state, { data }) => {
    state.valves.status = `success`;
    state.valves.errors = {};
    state.valves.data = data;
  },
  [constants.VALVES_LOG_LIST_REQUEST_ERROR]: (state, err) => {
    state.valves.status = `error`;
    state.valves.errors = err.response ? err.response.data : err;
  },
  [constants.FLOW_VALVES_GET_REQUEST]: (state) => {
    state.valve.status = `fetching`;
    state.valve.errors = {};
  },
  [constants.FLOW_VALVES_GET_REQUEST_SUCCESS]: (state, { data }) => {
    state.valve.status = `success`;
    state.valve.errors = {};
    state.valve.data = data;
  },
  [constants.FLOW_VALVES_GET_REQUEST_ERROR]: (state, err) => {
    state.valve.status = `error`;
    state.valve.errors = err.response ? err.response.data : err;
  },
  [constants.FLOW_VALVES_SET_SELECTED]: (state, valve) => {
    state.valve.status = `success`;
    state.valve.errors = {};
    state.valve.data = valve;
  },
  [constants.FLOW_DETAIL_SET_VIEW_COMPONENT]: (state, detail) => {
    state.status = `success`;
    state.errors = {};
    state.detail = detail;
  },
  [constants.FLOW_SIBLING_SET_SELECTED]: (state, sibling) => {
    state.sibling.status = "SUCCESS";
    state.sibling.errors = {};
    state.sibling.data = sibling;
  },
  [constants.FLOWS_LOG_RESET_ASSET]: (state) => {
    state.logDetail.status = `fetching`;
    state.logDetail.errors = {};
  },
  [constants.FLOWS_LOG_RESET_ASSET_SUCCESS]: (state, uid) => {
    state.logDetail.status = `success`;
    state.logDetail.errors = {};
    state.logDetail.data = {};

    const foundIndex = state.logList.data.results.findIndex(
      (x) => x.uid == uid
    );
    state.logList.data.results.splice(foundIndex, 1);
  },
  [constants.FLOWS_LOG_RESET_ASSET_ERROR]: (state, err) => {
    state.logDetail.status = `error`;
    state.logDetail.errors = err.response ? err.response.data : err;
  },
  [constants.VALVES_LOG_RESET]: (state) => {
    state.valve.status = `fetching`;
    state.valve.errors = {};
  },
  [constants.VALVES_LOG_RESET_SUCCESS]: (state) => {
    state.valve.status = "success";
    state.valve.errors = {};
  },
  [constants.VALVES_LOG_RESET_ERROR]: (state, err) => {
    state.valve.status = `error`;
    state.valve.errors = err.response ? err.response.data : err;
  },
  [constants.VALVES_LOG_LIST_RESET]: (state) => {
    state.valves.status = `success`;
    state.valves.errors = {};
    state.valves.data = { results: [] };
  },
  [constants.PIPELINE_REQUEST]: (state) => {
    state.pipeline.status = "fetching";
    state.pipeline.errors = {};
  },
  [constants.PIPELINE_REQUEST_SUCCESS]: (state, { data }) => {
    state.pipeline.status = "success";
    state.pipeline.errors = {};
    state.pipeline.data = data;
  },
  [constants.PIPELINE_REQUEST_ERROR]: (state, err) => {
    state.pipeline.status = "error";
    state.pipeline.errors = err;
  },
  [constants.FLOW_LOG_RENAME_ASSET]: (state) => {
    state.renamed.status = "fetching";
    state.renamed.errors = {};
  },
  [constants.FLOW_LOG_RENAME_ASSET_SUCCESS]: (state, { payload }) => {
    const foundIndex = state.logList.data.results.findIndex(
      (x) => x.uid == payload.flowlog_uid
    );
    state.logList.data.results.splice(foundIndex, 1);
  },
  [constants.FLOW_LOG_RENAME_ASSET_ERROR]: (state, err) => {
    state.renamed.status = "error";
    state.renamed.errors = err;
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
