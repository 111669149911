<template>
  <div>
    <div class="container-fluid">
      <div class="rc-page-header">
        <h1>Conduit</h1>
        <div class="rc-intro">
          <p>
            Your one-stop service for process tracking, file delivery and other
            cloud-based wizardry.
          </p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="card">
            <div class="card-body">
              <h2>Flow</h2>
              <p>
                Find your files as they go through the mind boggling world of
                automatic processes.
              </p>
            </div>
            <div class="card-footer">
              <rc-btn
                :to="{ name: 'flow.list', params: { locale: $i18n.locale } }"
                variant="primary"
                class="btn-block"
                >Monitor flows</rc-btn
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="card">
            <div class="card-body">
              <h2>Delivery</h2>
              <p>
                Tracking ongoing deliveries has just become easier then ever.
                Get ready for high-paced status updates.
              </p>
            </div>
            <div class="card-footer">
              <rc-btn
                :to="{ name: 'deliveries.list' }"
                variant="primary"
                class="btn-block"
                >Monitor deliveries</rc-btn
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="card">
            <div class="card-body">
              <h2>Archive</h2>
              <p>
                Browse the evergrowing list of archived files with our top of
                the line document seeker tool.
              </p>
            </div>
            <div class="card-footer">
              <rc-btn
                :to="{
                  name: 'archives.list',
                  params: { locale: $i18n.locale }
                }"
                variant="primary"
                class="btn-block"
                >Search archives</rc-btn
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="card">
            <div class="card-body">
              <h2>Upload</h2>
              <p>
                Ingest your very own local medias to Conduit flows. Sit back
                relax and let the magic begin.
              </p>
            </div>
            <div class="card-footer">
              <rc-btn
                :to="{ name: 'upload.list', params: { locale: $i18n.locale } }"
                variant="primary"
                class="btn-block"
                >Upload files</rc-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <RcPrismTextEditor />
    </div>
  </div>
</template>

<script>
import RcPrismTextEditor from "@/libvue/components/textEditor/PrimsEditor.vue";

export default {
  name: "HomePage",
  components: {
    RcPrismTextEditor
  }
};
</script>
