export const TEAM_DELIVERIES_APPEND_NEXT = "TEAM_DELIVERIES_APPEND_NEXT";
export const TEAM_DELIVERIES_APPEND_NEXT_SUCCESS =
  "TEAM_DELIVERIES_APPEND_NEXT_SUCCESS";
export const TEAM_DELIVERIES_APPEND_NEXT_ERROR =
  "TEAM_DELIVERIES_APPEND_NEXT_ERROR";

export const TEAM_DELIVERIES_LOG_REQUEST = "TEAM_DELIVERIES_LOG_REQUEST";
export const TEAM_DELIVERIES_LOG_REQUEST_SUCCESS =
  "TEAM_DELIVERIES_LOG_REQUEST_SUCCESS";
export const TEAM_DELIVERIES_LOG_REQUEST_ERROR =
  "TEAM_DELIVERIES_LOG_REQUEST_ERROR";

export const DELIVERIES_SET_SELECTED = "DELIVERIES_SET_SELECTED";
export const DELIVERIES_SET_VIEW_COMPONENT = "DELIVERIES_SET_VIEW_COMPONENT";
export const DELIVERY_SEARCH_SET_ORDER_BY = "DELIVERY_SEARCH_SET_ORDER_BY";

export const DELIVERIES_LOG_RESTART = "DELIVERIES_LOG_RESTART";
export const DELIVERIES_LOG_RESTART_SUCCESS = "DELIVERIES_LOG_RESTART_SUCCESS";
export const DELIVERIES_LOG_RESTART_ERROR = "DELIVERIES_LOG_RESTART_ERROR";

export const DELIVERIES_SET_SELECTED_DISPATCH =
  "DELIVERIES_SET_SELECTED_DISPATCH";

export const DELIVERIES_SAVE_META = "DELIVERIES_SAVE_META";
export const DELIVERIES_SAVE_META_SUCCESS = "DELIVERIES_SAVE_META_SUCCESS";
export const DELIVERIES_SAVE_META_ERROR = "DELIVERIES_SAVE_META_ERROR";

export const DELIVERY_GET_ACTIONS = "DELIVERY_GET_ACTIONS";
export const DELIVERY_GET_ACTIONS_SUCCESS = "DELIVERY_GET_ACTIONS_SUCCESS";
export const DELIVERY_GET_ACTIONS_ERROR = "DELIVERY_GET_AVAILABILITY_ERROR";

export const DELIVERY_ALLOW_VERSION = "DELIVERY_ALLOW_VERSION";
export const DELIVERY_ALLOW_VERSION_SUCCESS = "DELIVERY_ALLOW_VERSION_SUCCESS";
export const DELIVERY_ALLOW_VERSION_ERROR = "DELIVERY_ALLOW_VERSION_ERROR";
