var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.displayRegistration)?_c('div',{staticClass:"rc-auth-status-bar"},[(!_vm.teamEnabled)?[(!_vm.profile.domain_approved)?[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t("auth.status.signed_in_as_a_guest")))]),_c('li',[_vm._v(_vm._s(_vm.$t("auth.status.domain_is_pending")))])])]:_vm._e()]:[(_vm.organisationIsNew)?[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t("auth.status.signed_in_as_a_guest")))]),_c('li',[_c('router-link',{attrs:{"to":{
              name: 'user.account.team.join',
              params: { locale: _vm.$i18n.locale }
            }}},[_vm._v(_vm._s(_vm.$t("auth.status.complete_registration")))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("auth.status.full_access")))])],1)])]:_vm._e(),(_vm.organisationIsPending)?[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t("auth.status.signed_in_as_a_guest")))]),_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("auth.status.org_is_pending"))+" ")])])])]:_vm._e(),(_vm.organisationIsApproved && !_vm.userIsInATeam)?[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t("auth.status.signed_in_as_a_guest")))]),_c('li',[_c('router-link',{attrs:{"to":{
              name: 'user.account.team.join',
              params: { locale: _vm.$i18n.locale }
            }}},[_vm._v(_vm._s(_vm.$t("auth.status.join_a_team")))]),_c('span',[_vm._v("  "+_vm._s(_vm.$t("auth.status.full_access")))])],1)])]:_vm._e()]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }