<template>
  <footer class="site-footer">
    <div class="container-fluid">
      <p class="rc-copy" v-html="copy"></p>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SiteFooter",

  data() {
    return {
      version: ""
    };
  },

  computed: {
    ...mapState({
      categories: (state) => state.catalog.categories
    }),
    copy() {
      return `${this.version} © 2020 CBC &amp; Radio-Canada. All rights reserved.`;
    }
  },
  mounted() {
    let metas = document.getElementsByTagName("meta") || [];

    for (let m of metas) {
      if (m.getAttribute("name") === "version") {
        this.version = m.getAttribute("content");
      }
    }
  }
};
</script>