import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";

function load(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `./${view}.vue`);
}

const routes = {
  path: "team_storage",
  component: load("Base"),
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: ":source_storage_id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:source_name(.*)",
      name: "asset.detail",
      component: load("AssetDetail"),
      beforeEnter: ifUserInTeam,
      meta: {
        isBypassingPageTransition: true,
        layout: "dashboard"
      }
    }
  ]
};

export default routes;
