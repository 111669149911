import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";

function load(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `./${view}.vue`);
}

const routes = {
  path: "flow",
  component: load("Base"),
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: "list",
      name: "flow.list",
      component: load("List"),
      beforeEnter: ifUserInTeam,
      meta: {
        isBypassingPageTransition: true,
        layout: "dashboard"
      },

      children: [
        {
          path:
            ":uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
          name: "flow.detail",
          component: load("Detail"),
          meta: {
            isBypassingPageTransition: true,
            layout: "dashboard"
          }
        }
      ]
    },
    {
      path:
        "detail/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
      name: "flow.detail.sharable",
      component: load("Detail"),
      meta: {
        isBypassingPageTransition: true,
        layout: "dashboard"
      }
    },
    {
      path:
        "source/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/config",
      name: "flow.source.config",
      component: load("ConfigRegulator"),
      props: true,
      meta: {
        layout: "dashboard"
      }
    },
    {
      path:
        ":type(source|process)/rules/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/config",
      name: "flow.rules.config",
      component: load("ConfigRules"),
      props: true,
      meta: {
        layout: "dashboard"
      }
    },
    {
      path:
        "pipeline/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/config",
      name: "flow.pipeline.config",
      component: load("ConfigPipeline"),
      props: true,
      meta: {
        layout: "dashboard"
      }
    }
  ]
};

export default routes;
