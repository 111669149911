const formValidationMixin = {
  methods: {
    validate(field) {
      field.validate.blured = true;
      field.value = field.value ? field.value.trim() : "";

      // validation is not active
      if (!field.validate.active) return;

      // if field should be validated
      let toValidate = this.isToValidate(field);

      if (toValidate && field.value) {
        // if field as correct max lenght
        if (field.type == "url") {
          this.validateUrl(field);
        } else if (field.type == "email") {
          this.validateEmail(field);
        } else if (field.type == "text") {
          this.validateText(field);
        }
        this.validateLength(field);
      }
    },
    validateAll() {
      let invalidFields = [];
      this.form.fields.forEach((field) => {
        this.validate(field);

        if (field.validate.isValid == false) {
          invalidFields.push(field);
        }
      });
      return invalidFields.length ? false : true;
    },
    isToValidate(field) {
      if (field.required && !field.value) {
        field.validate.isValid = false;
        field.validate.message = `${field.label} ${this.$t(
          "common.is_required"
        )}`;
        return true;
      } else if (!field.required && field.value) {
        field.validate.isValid = true;
        field.validate.message = "";
        return true;
      } else if (field.required && field.value) {
        field.validate.isValid = true;
        field.validate.message = "";
        return true;
      } else {
        field.validate.isValid = null;
        field.validate.message = "";
        return false;
      }
    },
    validateLength(field) {
      if (field.value && field.value.length > field.validate.maxLength) {
        field.validate.isValid = false;
        field.validate.message = `${field.label} should not exceed ${field.validate.maxLength} characters`;
        return false;
      }

      if (!field.value && field.required) {
        field.validate.isValid = false;
        field.validate.message = `${field.label} is required`;
        return false;
      }

      return field.value.length > field.validate.maxLength;
    },
    validateUrl(field) {
      let expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      var regex = new RegExp(expression);

      if (field.value.match(regex)) {
        field.validate.isValid = true;
        field.validate.message = "";
        return true;
      } else {
        field.validate.isValid = false;
        field.validate.message = `Please provide a valid ${field.label.toLowerCase()}`;
        return false;
      }
    },
    validateText(field) {
      if (/^[a-zA-ZÀ-ÿ-._0-9 ]*$/.test(field.value)) {
        field.validate.isValid = true;
        field.validate.message = "";
        return true;
      }
      field.validate.isValid = false;
      field.validate.message = `Special characters are not allowed`;
      return false;
    },
    validateEmail(field) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(field.value)) {
        field.validate.isValid = true;
        field.validate.message = "";
        return true;
      }
      field.validate.isValid = false;
      field.validate.message = `Please provide a valid ${field.label.toLowerCase()}`;
      return false;
    },
    formatPayload() {
      const payload = {};
      this.form.fields.forEach((field) => {
        if (field.value) {
          payload[field.name] = field.value;
        }
      });
      return payload;
    },
    resetForm() {
      this.form.fields.forEach((f) => {
        f.value = f.initial ? f.initial : (f.value = "");
        f.validate.blured = false;
        f.validate.active = true;
        f.validate.isValid = null;
      });
    }
  }
};

export { formValidationMixin };
