import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";

import i18n from "#/locales";
import store from "#/stores";

const routes = [
  {
    path: "teams",
    name: "user.account.team.join",
    component: () => import("@/libvue/apps/team/components/OrgTeams.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: "account",
      alias: [null, null, "equipes"],
      title: "pageMeta.catalog.title",
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("team.team", 2),
            active: true
          }
        ]
      }
    }
  },
  {
    path: "teams/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/memberships",
    alias:
      ":uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/membres",
    beforeEnter: ifUserInTeam,
    name: "user.account.team.users",
    component: () => import("@/libvue/apps/team/components/Memberships.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: "account",
      alias: [null, null, "equipes", null, "membres"],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("team.team", 2),
            to: { name: "user.account.team.join" }
          },
          {
            text: () => store.getters.getProfile.team_name,
            active: true
          }
        ]
      }
    }
  }
];

export default routes;
