<template>
  <b-modal :id="modalId" :title="$t('team.create_team')">
    <template #modal-footer="{ cancel }">
      <button class="btn btn-secondary" @click="cancel">Cancel</button>
      <button class="btn btn-lg btn-primary" @click="handleOk()">
        {{ $t("common.create") }}
      </button>
    </template>

    <div>
      <div class="row no-gutters mb-2">
        <label for="name" class="col-form-label col-md-12">{{
          $t("team.team_name")
        }}</label>
        <div class="col">
          <input
            id="name"
            name="name"
            type="text"
            :value="$v.form.name.$model"
            class="form-control"
            :class="validateState('name') != false ? '' : 'is-invalid'"
            :aria-invalid="validateState('name') != false ? null : true"
            :aria-describedby="
              validateState('name') != false ? null : 'feedback_invalid__name'
            "
            @change="setValue('name', $event)"
          />

          <div
            v-if="validateState('name') == false"
            id="feedback_invalid__name"
            aria-live="assertive"
            aria-atomic="true"
            class="invalid-feedback d-block"
          >
            <div v-if="!$v.form.name.alpha">
              {{ $t("auth.alpha_invalid_block") }}
            </div>
            <div v-if="!$v.form.name.required">
              {{ $t("auth.required_invalid_block") }}
            </div>
            <div v-if="!$v.form.name.maxLength">
              {{ $t("auth.maxLength_invalid") }}
              ({{ $v.form.name.$params.maxLength.max }})
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  TEAM_JOIN_CREATE_FROM_ACCOUNT_REQUEST,
  TEAM_JOIN_REQUEST,
  TEAM_SWITCH
} from "@/libvue/apps/team/actions";

import { required, maxLength } from "vuelidate/lib/validators";

const alpha = (value) => {
  return /^[a-zA-ZÀ-ÿ-._0-9 ]*$/.test(value);
};

export default {
  name: "CreateNewTeamModal",

  data() {
    return {
      modalId: "createNewTeamModal",
      errors: {},
      form: { name: "" }
    };
  },

  validations: {
    form: {
      name: { required, alpha, maxLength: maxLength(60) }
    }
  },

  computed: {
    ...mapState({
      profile: (state) => state.auth.user
    })
  },

  methods: {
    handleOk() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      this.create({ name: this.form.name })
        .then((res) => {
          const message =
            res.data.status == "approved"
              ? this.$t("team.request_approved", { name: res.data.name })
              : this.$t("team.request_sent", { name: res.data.name });

          this.$notify.success(message);
          this.$bvModal.hide(this.modalId);
          if (res.data.status == "approved") {
            this.$router.push({
              name: "user.account.summary",
              params: { locale: this.$i18n.locale }
            });
          }
        })
        .catch(() => this.$notify.danger(this.$t("team.request_error")));
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    setValue(key, event) {
      let value = event.target.value;

      this.form[key] = value;
      this.$v.form[key].$touch();
    },

    ...mapActions({
      create: TEAM_JOIN_CREATE_FROM_ACCOUNT_REQUEST,
      join: TEAM_JOIN_REQUEST,
      switch: TEAM_SWITCH
    })
  }
};
</script>
